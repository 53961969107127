import { Avatar, Box, Button, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as ChatIcon } from "../../../static/svg/chat.svg";
import { ReactComponent as WalletIcon } from "../../../static/svg/wallet-white.svg";

import { Link } from "react-router-dom";
import PaymeDialog from "../Dashboard/PaymeDialog";
import useDialog from "../../../hooks/use-dialog";

type TextProps = {
  label?: string | number | any;
  text?: string | number | any;
};
type Props = {
  name?: string;
  phone?: string | number;
  employerRefId?: string | number;
  customerId?: string | number;
  imgSrc?: string;
  msgPath: string;
  tranxPath: string;
  details?: TextProps[];
};

const FlexCard = (props: TextProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginY: "5px",
        gap: 2,
      }}
    >
      <Typography
        variant="subtitle2"
        fontSize={"9px"}
        sx={{
          color: "#194049",
        }}
      >
        {props.label}
      </Typography>

      <Typography
        variant="subtitle2"
        fontSize={"9px"}
        sx={{
          color: "#BBC5C9",
        }}
      >
        {props.text}
      </Typography>
    </Box>
  );
};

const PhotoCard = (props: Props) => {
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  // const handleClick = () => {
  //   console.log("emp", props.employerRefId);
  //   console.log("cus", props.customerId);
  // };
  return (
    <React.Fragment>
      <Box
        sx={{
          width: { sm: "190px", md: "190px" },
          height: { sm: "255px", md: "255px" },
          border: "1px solid #DFDFDF",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          marginY: "10px",
          position: "relative",
        }}
      >
        <Box sx={{}} mb={3}>
          <Avatar
            alt={props.name && props.name[0].toLocaleUpperCase()}
            src={props.imgSrc || (props.name && props.name[0])}
            sx={{
              width: 82,
              height: 82,
              border: "1px solid #E7E7E7",
              marginX: "auto",
              backgroundColor: "#8EBC76",
            }}
          />

          <Typography
            variant="subtitle2"
            fontSize={"14px"}
            sx={{
              color: "#194049",
              textAlign: "center",
              textTransform: "capitalize",
            }}
            mt={2}
          >
            {props.name}
          </Typography>
          <Box
            sx={{
              backgroundColor: "#EAEEE8",
              width: "82px",
              height: "16px",
              borderRadius: "4px",
              marginX: "auto",
              justifyContent: "center",
              alignItems: "center",
              paddingY: "3px",
            }}
          >
            <Typography
              variant="subtitle2"
              fontSize={"8px"}
              sx={{
                color: "#8EBC76",
                textAlign: "center",
              }}
            >
              {props.phone}
            </Typography>
          </Box>

          <Box mt={2}>
            {props.details &&
              props.details.map((item) => (
                <FlexCard
                  label={item.label}
                  text={item.text}
                  key={item.label}
                />
              ))}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            height: "30px",
            position: "absolute",
            bottom: 0,
            width: "100%",
            textAlign: "center",
            // borderBottomLeftRadius: "8px",
            // borderBottomRightRadius: "8px",
          }}
          //   className="!rounded-bl-lg !rounded-br-lg"
        >
          <Box
            sx={{
              backgroundColor: "#194049",
              width: "50%",
              borderBottomLeftRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Button
              // to={"#"}
              onClick={handleOpenDialog}
              className=" no-underline !flex items-center gap-2"
            >
              <WalletIcon className="w-4 h-4" />
              <Typography
                variant="subtitle2"
                fontSize={"8px"}
                sx={{
                  color: "#fff",
                }}
              >
                Bill me
              </Typography>
              {/* <Typography
                variant="subtitle2"
                fontSize={"8px"}
                sx={{
                  color: "#fff",
                }}
              >
                Transactions
              </Typography> */}
            </Button>
          </Box>
          <Box
            sx={{
              backgroundColor: "#EEEEEE",
              width: "50%",
              borderBottomRightRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link to={props.tranxPath} className=" no-underline">
              <Typography
                variant="subtitle2"
                fontSize={"8px"}
                sx={{
                  color: "#0060F0",
                }}
              >
                View Transactions
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>

      {openDialog && (
        <PaymeDialog
          open={openDialog}
          onClose={handleCloseDialog}
          employerRefId={props.employerRefId}
          customerId={props.customerId}
          empName={props.name}
        />
      )}
    </React.Fragment>
  );
};

export default PhotoCard;
