import dayjs, { Dayjs } from "dayjs";
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  FC,
  ReactNode,
} from "react";
import { useLocation } from "react-router-dom";
import DashboardContext from "./dashboard.context";

interface OnboardForm {
  type?: string;
  email?: string;
  firstname?: string;
  middlename?: string;
  lastname?: string;
  area?: string;
  lcda?: string;
  phone?: string;
  whatsapp?: string;
  password?: string;
  subscriptionType?: string;
  otp?: number;

  // employee additionals
  state?: string;
  address?: string;
  sex?: string;
  role?: string;
  healthVerify: string;
  healthVerifyAmount: number;
  healthCheck: {
    medic: boolean;
    vaccine: boolean;
    disease: boolean;
    vision: boolean;
    drug: boolean;
  };
  bgCheck: {
    id: boolean;
    address: boolean;
    guarantor: boolean;
    employment: boolean;
    education: boolean;
  };
  saveState: boolean;
  startDate?: Dayjs | null;
  endDate?: Dayjs | null;
  salary: string;
  payFrequency?: string;
  processPayment?: string;
  paymentDue?: string;
  meals?: string;
  transportation?: string;
  accomodation?: string;
  airtime?: string;
  otherBenefits?: string;
  hmoCheck?: string;
  prorateSalary?: string;
  bonus?: string;
}

interface ContractForm {
  id: number;
  employeeUUID?: number;
  employerRefId?: number;
  role?: string;
  documentName: string;
  editorHtml: string;
  contractRef?: string;
  contractInvite?: string;
  employerName: string;
  employeeName: string;
  employeeEmail: string;
  employeePhone: string | number;
  employeeAddress: string;
  // contractType?: string;
  signState: boolean;
  employerSignature: string | any;
  employeeSignature: string | any;
  contractLength: string;
  contractType?: string;
  terminationDays?: string;
  startDate?: Dayjs | null;
  endDate?: Dayjs | null;
  signedPdfUrl?: string;
}

interface AccountInfo {
  customerId: number | any;
  AccountNumber?: string | any;
  bankName?: string | any;
  workingDays?: string;
  balance?: number | any;
  createdDate: Dayjs | null;
  createdBy: string;
}

interface OnboardContextProps {
  contractForm: ContractForm;
  addToContract: (data: Partial<ContractForm>) => void;
  clearContract: () => void;
  form: OnboardForm;
  addToOnboard: (data: Partial<OnboardForm>) => void;
  clearForm: () => void;
  accountDetails: AccountInfo;
  getAcctDetails: () => void;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const OnboardContext = createContext<OnboardContextProps>({
  contractForm: {
    id: 0,
    employeeUUID: 0,
    employerRefId: 0,
    role: "",
    editorHtml: "",
    documentName: "",
    contractRef: "",
    contractInvite: "",
    employerName: "",
    employeeName: "",
    employeeEmail: "",
    employeePhone: "",
    employeeAddress: "",
    // contractType: "",
    employerSignature: "",
    employeeSignature: "",
    signState: false,
    contractLength: "",
    startDate: dayjs("2024-01-01"),
    endDate: dayjs("2024-01-01"),
    contractType: "",
    terminationDays: "",
    signedPdfUrl: "",
  },
  form: {
    type: "",
    email: "",
    firstname: "",
    middlename: "",
    lastname: "",
    area: "",
    lcda: "",
    phone: "",
    whatsapp: "",
    password: "",
    otp: 0,

    // employee additionals
    state: "",
    address: "",
    healthVerify: "",
    healthVerifyAmount: 0,
    healthCheck: {
      medic: false,
      vaccine: false,
      disease: false,
      vision: false,
      drug: false,
    },
    bgCheck: {
      id: false,
      address: false,
      guarantor: false,
      employment: false,
      education: false,
    },
    saveState: false,
    startDate: dayjs("2024-01-01"),
    endDate: dayjs("2024-01-01"),
    hmoCheck: "",
    salary: "",
    payFrequency: "",
    processPayment: "",
    paymentDue: "",
    meals: "",
    transportation: "",
    accomodation: "",
    airtime: "",
    otherBenefits: "",
    prorateSalary: "",
    bonus: "",
    sex: "",
    role: "",
  },
  accountDetails: {
    customerId: 0,
    AccountNumber: "",
    bankName: "",
    workingDays: "",
    balance: 0,
    createdDate: dayjs("2024-01-01"),
    createdBy: "",
  },
  getAcctDetails: () => {},
  addToOnboard: () => {},
  clearForm: () => {},
  addToContract: () => {},
  clearContract: () => {},
  isLoading: false,
  setIsLoading: () => {},
  activeStep: 0,
  setActiveStep: () => {},
});

export const OnboardProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [form, setForm] = useState<OnboardForm>(() => {
    const storedForm = localStorage.getItem("onboardform");
    return storedForm
      ? JSON.parse(storedForm)
      : {
          type: "",
          email: "",
          firstname: "",
          middlename: "",
          lastname: "",
          area: "",
          lcda: "",
          phone: "",
          whatsapp: "",
          password: "",
          subscriptionType: "",
          otp: 0,

          // employee additionals
          state: "",
          address: "",
          healthVerify: "",
          healthVerifyAmount: 0,
          healthCheck: {
            medic: false,
            vaccine: false,
            disease: false,
            vision: false,
            drug: false,
          },
          bgCheck: {
            id: false,
            address: false,
            guarantor: false,
            employment: false,
            education: false,
          },
          saveState: false,
          startDate: dayjs("2024-01-01"),
          endDate: dayjs("2024-01-01"),
          hmoCheck: "",
          salary: "",
          payFrequency: "",
          processPayment: "",
          paymentDue: "",
          meals: "",
          transportation: "",
          accomodation: "",
          airtime: "",
          otherBenefits: "",
          prorateSalary: "",
          bonus: "",
          sex: "",
          role: "",
        };
  });
  const [contractForm, setContractForm] = useState<ContractForm>(() => {
    const storedContractForm = localStorage.getItem("contractForm");
    return storedContractForm
      ? JSON.parse(storedContractForm)
      : {
          id: 0,
          employeeUUID: 0,
          role: "",
          documentName: "",
          editorHtml: "",
          contractRef: "",
          contractInvite: "",
          employeeName: "",
          employeeEmail: "",
          employeePhone: "",
          employeeAddress: "",
          // contractType: "",
          employerSignature: "",
          employeeSignature: "",
          signState: false,
          contractLength: "",
          contractType: "",
          terminationDays: "",
          startDate: dayjs("2024-01-01"),
          endDate: dayjs("2024-01-01"),
          signedPdfUrl: "",
        };
  });

  const [accountDetails, setAccountDetails] = useState<AccountInfo>({
    customerId: 0,
    AccountNumber: "",
    bankName: "",
    workingDays: "",
    balance: 0,
    createdDate: dayjs("2024-01-01"),
    createdBy: "",
  });

  const dashboardCtx = useContext(DashboardContext);
  const { organization } = dashboardCtx;
  const customId = organization?.customerId;

  const getAcctDetails = async () => {
    if (!customId) {
      console.error("Customer ID is undefined");
      return;
    }

    const url = `/account/fetch-account-by-customerid?customerId=${customId}`;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${url}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const jsonData = await response.json();
      setAccountDetails(jsonData.data);
    } catch (err) {
      console.error("Cannot find Data");
    }
  };

  React.useEffect(() => {
    if (customId) {
      getAcctDetails();
    }
  }, []);

  const [activeStep, setActiveStep] = useState<number>(() => {
    const storedStep = localStorage.getItem("activeStep");
    return storedStep ? JSON.parse(storedStep) : 0;
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();
  const activeLocation = location.pathname;
  // console.log("activeLocation", activeLocation);
  const storedLocationString = localStorage.getItem("activeLocation");
  const storedLocation = storedLocationString
    ? JSON.parse(storedLocationString)
    : null;

  const addToContract = (data: Partial<ContractForm>) => {
    setContractForm((prevForm) => ({ ...prevForm, ...data }));
  };

  const addToOnboard = (data: Partial<OnboardForm>) => {
    setForm((prevForm) => ({ ...prevForm, ...data }));
  };

  const clearContract = () => {
    localStorage.removeItem("contractForm");
    if (contractForm) {
      // Check if contractForm is defined
      setContractForm({
        id: 0,
        employeeUUID: 0,
        employerRefId: 0,
        role: "",
        employerName: "",
        documentName: "",
        editorHtml: "",
        contractRef: "",
        contractInvite: "",
        employeeName: "",
        employeeEmail: "",
        employeePhone: "",
        employeeAddress: "",
        // contractType: "",
        employerSignature: "",
        employeeSignature: "",
        signState: false,
        contractLength: "",
        contractType: "",
        terminationDays: "",
        startDate: dayjs("2024-01-01"),
        endDate: dayjs("2024-01-01"),
        signedPdfUrl: "",
      });
    }
  };
  const clearForm = () => {
    localStorage.removeItem("onboardform");
    setForm({
      type: "",
      email: "",
      firstname: "",
      middlename: "",
      lastname: "",
      area: "",
      lcda: "",
      phone: "",
      whatsapp: "",
      password: "",
      otp: 0,
      subscriptionType: "",

      // Additionals for employee
      state: "",
      address: "",
      healthVerify: "",
      healthVerifyAmount: 0,
      healthCheck: {
        medic: false,
        vaccine: false,
        disease: false,
        vision: false,
        drug: false,
      },
      bgCheck: {
        id: false,
        address: false,
        guarantor: false,
        employment: false,
        education: false,
      },
      saveState: false,
      startDate: dayjs("2024-01-01"),
      endDate: dayjs("2024-01-01"),
      hmoCheck: "",
      salary: "",
      payFrequency: "",
      processPayment: "",
      paymentDue: "",
      meals: "",
      transportation: "",
      accomodation: "",
      airtime: "",
      otherBenefits: "",
      prorateSalary: "",
      bonus: "",
      role: "",
    });
    localStorage.removeItem("activeStep");
    setActiveStep(0);
  };

  useEffect(() => {
    localStorage.setItem("contractForm", JSON.stringify(contractForm));
  }, [contractForm]);

  useEffect(() => {
    localStorage.setItem("onboardform", JSON.stringify(form));
  }, [form]);

  useEffect(() => {
    localStorage.setItem("activeStep", JSON.stringify(activeStep));
  }, [activeStep]);

  useEffect(() => {
    // Update storedLocation in local storage when location changes
    localStorage.setItem("activeLocation", JSON.stringify(activeLocation));
  }, [activeLocation]);

  useEffect(() => {
    // Check if storedLocation exists and is different from activeLocation
    if (storedLocation && storedLocation !== activeLocation) {
      setActiveStep(0);
    }
  }, [activeLocation, storedLocation]);

  const contextValues: OnboardContextProps = {
    contractForm,
    addToContract,
    clearContract,
    form,
    addToOnboard,
    clearForm,
    accountDetails,
    getAcctDetails,
    activeStep,
    setActiveStep,
    isLoading,
    setIsLoading,
  };

  return (
    <OnboardContext.Provider value={contextValues}>
      {children}
    </OnboardContext.Provider>
  );
};

export const useOnboardForm = () => useContext(OnboardContext);
