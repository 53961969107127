import { useEffect } from "react";
import "./index.scss";
import "./App.module.scss";

import ScrollToTop from "./components/Layout/ScrollToTop";
import AppNavigator from "./navigator/Navigator";
import { DashboardContextProvider } from "./store/context/dashboard.context";
import { SnackbarContextProvider } from "./store/context/snackbar.context";
import { LayoutContextProvider } from "./store/context/layout.context";
import { OnboardProvider } from "./store/context/onboard-context";

function App() {
  return (
    <div id="root" className="caring-blocks">
      <ScrollToTop />
      <DashboardContextProvider>
        <SnackbarContextProvider>
          <LayoutContextProvider>
            <OnboardProvider>
              <AppNavigator />
            </OnboardProvider>
          </LayoutContextProvider>
        </SnackbarContextProvider>
      </DashboardContextProvider>
    </div>
  );
}

export default App;
