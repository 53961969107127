import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as EditIcon } from "../../../static/svg/pen.svg";
import { ReactComponent as DeleteIcon } from "../../../static/svg/bin.svg";
import {
  formatAmount,
  formatNumber,
  getURLWithQueryParams,
} from "../../../utils/utils";
import { useHistory } from "react-router-dom";
import { HttpResponse } from "../../../models/api.interface";
import useHttp from "../../../hooks/use-http";
import SnackbarContext from "../../../store/context/snackbar.context";
import ConfirmationDialog from "../../Common/Dialog/ConfirmationDialog";
import { TrainingAttendeesProps } from "../../../models/training.model";
import CBDialog from "../../Common/Dialog/CBDialog";
import AttendeeCard from "./AttendeeCard";

type Props = {
  id?: string | number;
  reference?: string | number;
  title?: string;
  type?: string;
  objectives?: string;
  attendees?: string | number;
  mode?: string;
  price?: number;
  date?: string;
  time?: string;
  training: any;
};

const TrainCard = (props: Props) => {
  let history = useHistory();
  let outlines = props.objectives && props.objectives.split(",");

  //   console.log("outlines", outlines);
  // console.log("training", props.training);
  const { loading, error, sendHttpRequest: deactivateTraining } = useHttp();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openAttendees, setOpenAttendees] = React.useState(false);

  const handleOpenAttendees = () => {
    setOpenAttendees(true);
  };

  const handleCloseAttendees = () => {
    setOpenAttendees(false);
  };

  const handleEdit = () => {
    // console.log("id to Edit", props.reference);
    history.push(`/operations/trainings/update/${props.id}`);
  };

  const newItem = {
    ...props.training,
    status: "INACTIVE",
  };

  // console.log("newItem", newItem);

  const handleDelete = async () => {
    // console.log("id to Delete", props.id);
    // event.preventDefault();
    const formData = new FormData();

    // Object.keys(newItem).forEach((key) => {
    //   formData.append(key, newItem[key]);
    // });

    formData.append("title", newItem.title);
    formData.append("price", newItem.price);
    formData.append("objectives", newItem.objectives);
    formData.append("level", newItem.level);
    formData.append("trainingMode", newItem.trainingMode);
    formData.append("category", newItem.category);
    formData.append("date", newItem.date);
    formData.append("startDate", newItem.startDate);
    formData.append("endDate", newItem.endDate);
    formData.append("address", newItem.address);
    formData.append("instructor", newItem.instructor);
    formData.append("enquiryContact", newItem.enquiryContact);
    formData.append("certName", newItem.certName);
    formData.append("status", newItem.status);
    formData.append("issuedBy", newItem.issuedBy);

    await deactivateTraining(
      process.env.REACT_APP_API_BASE_URL + "/training/update",
      {
        method: "PUT",
        body: JSON.stringify(newItem),
        // body: formData,
      },
      (response: HttpResponse<any>) => {
        toast({
          message: `Training with id ${newItem.id} deleted`,
          variant: "success",
        });
        window.location.reload();
      }
    );
  };

  const { sendHttpRequest } = useHttp();

  const [training, setTraining] = React.useState<TrainingAttendeesProps>();

  const fetchTraining = async () => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/training/byref",
        {
          reference: String(props.reference),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<any>) => {
        setTraining(response.data);
        // console.log("response 1", response.data.data);
        // console.log("response 2", response.data);
      }
    );
  };

  React.useEffect(() => {
    fetchTraining();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("training", training);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-evenly",
          alignItems: "center",
          //   py: 1,
          px: 1,
        }}
      >
        <Box sx={{ flexBasis: "18%" }}>
          <Typography
            variant="body1"
            fontSize={"12px"}
            sx={{
              color: "#194049",
            }}
          >
            {props.title}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ mr: 1 }} />
        <Box sx={{ flexBasis: "10%" }}>
          <Typography
            variant="body1"
            fontSize={"12px"}
            sx={{
              color: "#194049",
            }}
          >
            {props.mode}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ mr: 1 }} />
        <Box
          sx={{
            // width: "200px",
            flexBasis: "22%",
            py: 0.5,
          }}
        >
          <ul>
            {outlines &&
              outlines.map((item) => (
                <li key={item}>
                  <Typography
                    variant="body1"
                    fontSize={"12px"}
                    sx={{
                      color: "#194049",
                      // width: "20px",
                    }}
                    className=" capitalize line-clamp-1"
                  >
                    {" • "} {item}{" "}
                  </Typography>
                </li>
              ))}
          </ul>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ mr: 1 }} />

        <Box sx={{ flexBasis: "10%" }}>
          <Typography
            variant="body1"
            fontSize={"12px"}
            sx={{
              color: "#194049",
              // width: "20px",
            }}
          >
            {/* 04/06/2024 */}
            {props.date}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ mr: 1 }} />

        <Box sx={{ flexBasis: "10%" }}>
          <Typography
            variant="body1"
            fontSize={"12px"}
            sx={{
              color: "#194049",
              // width: "90px",
            }}
          >
            {props.time}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ mr: 1 }} />

        <Box sx={{ flexBasis: "10%" }}>
          <Typography
            variant="body1"
            fontSize={"12px"}
            sx={{
              color: "#194049",
              // width: "50px",
            }}
          >
            {props.price === 0 ? "Free" : formatAmount(props.price || 0)}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ mr: 1 }} />

        <Box sx={{ flexBasis: "10%", textAlign: "center" }}>
          <Typography
            variant="body1"
            fontSize={"12px"}
            sx={{
              color: "#194049",
              // width: "100px",
            }}
            className=" line-clamp-2"
          >
            {/* {training?.attendees?.length}{" "} */}
            {formatNumber(training?.attendees?.length || 0)}
          </Typography>

          {/* <Typography
            variant="body1"
            fontSize={"12px"}
            sx={{
              color: "#194049",
              // width: "100px",
            }}
            className=" line-clamp-2"
          >
            View
          </Typography> */}

          <Button
            variant="text"
            // startIcon={<EyeIcon className=" h-3 w-3 -mt-1 text-[#9400D3]" />}
            sx={{
              //   backgroundColor: "#D2E0CB",
              color: "#9400D3",
              //   border: "1px solid #B4D1A6",
              height: "22px",
              width: "62px",
              fontSize: "10px",
              alignItems: "center",
              textDecoration: "underline !important",
              ":hover": {
                color: "#9400D3",
                textDecoration: "underline !important",
              },
            }}
            onClick={handleOpenAttendees}
          >
            View
          </Button>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ mr: 1 }} />

        <Box sx={{ flexBasis: "10%", py: 1 }}>
          {/* <Typography
            variant="body1"
            fontSize={"12px"}
            sx={{
              color: "#194049",
              // width: "100px",
            }}
          >
            Edit
          </Typography> */}
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            sx={{
              backgroundColor: "#D2E0CB",
              color: "#56873D",
              border: "1px solid #B4D1A6",
              height: "22px",
              width: "62px",
              alignItems: "center",
              textDecoration: "none",
              pt: 1,
              fontSize: "10px",
            }}
            onClick={handleEdit}
          >
            Edit
          </Button>
          <Box pt={1} />
          <Button
            variant="text"
            startIcon={<DeleteIcon className=" -mt-1" />}
            sx={{
              //   backgroundColor: "#D2E0CB",
              color: "#FF0F00",
              //   border: "1px solid #B4D1A6",
              height: "22px",
              width: "62px",
              fontSize: "10px",
              alignItems: "center",
              textDecoration: "none !important",
              ":hover": {
                color: "#FF0F00",
                textDecoration: "none !important",
              },
            }}
            onClick={handleOpen}
          >
            Delete
          </Button>
        </Box>
      </Box>

      <ConfirmationDialog
        content={`Do you wish to delete ${newItem.title} ?`}
        open={open}
        onClose={handleClose}
        handleApprove={handleDelete}
        handleCancel={handleClose}
      />

      <CBDialog
        open={openAttendees}
        title={`${training?.trainings.title} Attendees List`}
        handleClose={handleCloseAttendees}
        // maxWidth={"md"}
        // scroll="paper"
        // fullWidth
      >
        <Box p={0} className=" bg-white ">
          <Box mx={1000} />
          <Box className=" mx-auto w-[100%] ">
            {training?.attendees?.map((attendee) => (
              <AttendeeCard
                key={attendee.customerId} // Always add a key when mapping over an array
                id={attendee.customerId}
                reference={attendee.reference}
                name={`${attendee.firstName} ${attendee.lastName}`}
                time={attendee.date}
                status={attendee.attendanceStatus}
                // handleRefetch={fetchTraining}
                handleRefetch={(e) => {
                  e.preventDefault(); // Optionally prevent the default event
                  fetchTraining(); // Call your fetch function
                }}
              />
            ))}
          </Box>
        </Box>
      </CBDialog>
    </>
  );
};

export default TrainCard;
