import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import ContractCard from "../components/Feature/Contract/ContractCard";
import useHttp from "../hooks/use-http";
import { ContractProps } from "../models/contract.model";
import { getURLWithQueryParams } from "../utils/utils";
import { HttpResponse } from "../models/api.interface";
import MHTextInput from "../components/Common/Form/MHTextInput";
import useInput from "../hooks/use-input";
import * as validators from "../utils/validators";

import { ReactComponent as SearchIcon } from "../static/svg/search-bold.svg";
import { MHSelect } from "../components/Common/Form/MHSelect";
import { HouseholdRoles } from "../utils/contracts";
import { MHPagination } from "../components/Common/UI/MHPagination";
import dayjs from "dayjs";
import DataLoader from "../components/Common/Loading/DataLoader";
import { Link } from "react-router-dom";

type Props = {};

const Contract = (props: Props) => {
  const {
    value: enteredName,
    valid: enteredNameeIsValid,
    onChange: contractNameInputChangeHandler,
    onBlur: contractNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEmployee,
    onChange: employeeNameInputChangeHandler,
    onBlur: employeeNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEmployer,
    onChange: employerNameInputChangeHandler,
    onBlur: employerNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredRole,
    valid: enteredRoleIsValid,
    onChange: contractRoleInputChangeHandler,
    onBlur: contractRoleInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const { sendHttpRequest } = useHttp();

  const [contracts, setContracts] = React.useState<ContractProps[]>([]);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employer/contract/all",
        {
          employerRefId: String("ABC"),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<ContractProps[]>) => {
        // console.log("Contract Options", response.data);
        setContracts(response.data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const completedContracts = contracts.filter(
    (contract) => contract.status === "COMPLETED"
  );
  const pendingContracts = contracts.filter(
    (contract) => contract.status === "CREATED"
  );

  // console.log("contracts", contracts);
  // console.log("completedContracts", completedContracts);
  // console.log("pendingContracts", pendingContracts);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [selectedContract, setSelectedContract] = React.useState<
    ContractProps[]
  >([]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCurrentPage(1);
  };

  React.useEffect(() => {
    let filteredContracts = value === 0 ? completedContracts : pendingContracts;

    if (enteredName) {
      filteredContracts = filteredContracts.filter((contract) =>
        contract?.contractName
          ?.toLowerCase()
          .includes(enteredName.toLowerCase())
      );
    }

    if (enteredEmployee) {
      filteredContracts = filteredContracts.filter((contract) =>
        contract?.employeeName
          ?.toLowerCase()
          .includes(enteredEmployee.toLowerCase())
      );
    }

    if (enteredEmployer) {
      filteredContracts = filteredContracts.filter((contract) =>
        contract?.employerName
          ?.toLowerCase()
          .includes(enteredEmployer.toLowerCase())
      );
    }

    // Only set state if it has changed to avoid infinite loops
    setSelectedContract((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify(filteredContracts)) {
        return filteredContracts;
      }
      return prev;
    });
  }, [
    value,
    completedContracts,
    pendingContracts,
    enteredName,
    enteredEmployee,
    enteredEmployer,
  ]);

  // React.useEffect(() => {
  //   if (value === 0) {
  //     setSelectedContract(completedContracts);
  //   } else {
  //     setSelectedContract(pendingContracts);
  //   }
  // }, [value, completedContracts, pendingContracts]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(9);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = selectedContract.slice(firstPostIndex, lastPostIndex);

  let itemsPerPage = 9;
  let noOfPage = Math.ceil((selectedContract?.length || 0) / itemsPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const todayDate = new Date();
  const newdate = dayjs(todayDate);

  if (contracts.length === 0) {
    return <DataLoader open />;
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          //   color: "#194049",
          paddingX: "20px",
        }}
      >
        <Typography
          variant="subtitle1"
          fontSize={"19px"}
          paddingTop={5}
          paddingBottom={3}
        >
          Contracts and Agreement
        </Typography>

        <Box sx={{ width: "100%" }}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{ textTransform: "capitalize", fontSize: "12px" }}
                label="Completed"
                {...a11yProps(0)}
              />
              <Tab
                sx={{ textTransform: "capitalize", fontSize: "12px" }}
                label="Pending"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
        </Box>

        <Box className=" lg:flex lg:flex-row lg:gap-6">
          <Box
            // sx={{ display: { sm: "flex" }, gap: { xs: 3, md: 8 } }}
            className="  lg:basis-[78%]  "
          >
            <Box
              sx={{ display: { sm: "flex" }, gap: { xs: 1, md: 2 } }}
              className="  lg:basis-[100%] grid grid-cols-2 lg:grid-cols-3"
            >
              {currentPosts.map((contract) => (
                <Link
                  to={`/operations/contracts/preview/${contract?.customerId}`}
                  className=" no-underline"
                  key={contract.id}
                >
                  <ContractCard
                    key={contract.id}
                    customerId={contract?.customerId}
                    contractName={contract?.contractName}
                    employer={contract?.employerName}
                    employee={contract?.employeeName}
                    status={contract?.status}
                    date={dayjs(contract?.createdDate).format("MMMM D, YYYY")}
                    since={`${newdate.diff(
                      contract?.createdDate,
                      "days"
                    )} day(s)`}
                  />
                </Link>
              ))}
            </Box>

            <Box display={"flex"} justifyContent={"center"} my={2}>
              <MHPagination
                count={noOfPage}
                variant="outlined"
                shape="rounded"
                page={currentPage}
                onChange={handlePageChange}
                color={"primary"}
              />
            </Box>
          </Box>
          <Box
            // sx={{ display: { sm: "flex" }, gap: { xs: 3, md: 8 } }}
            className=" mt-5 lg:mt-0 lg:basis-[22%] flex"
          >
            <Box
              sx={{
                border: "1px solid #D9D9D9",
                width: { xs: "100%", md: "243px" },
                height: "317px",
                borderRadius: "4px",
                textAlign: "center",
                paddingX: "10px",
              }}
            >
              <Typography
                variant="body1"
                fontSize={"12px"}
                paddingTop={5}
                paddingBottom={3}
              >
                {selectedContract.length}{" "}
                {value === 0 ? "completed" : "pending"} contract
              </Typography>

              <MHTextInput
                id="contractName"
                type="text"
                placeholder="Contract Name"
                value={enteredName}
                onChange={contractNameInputChangeHandler}
                onBlur={contractNameInputBlurHandler}
                required
                // autoFocus
                startAdornment={<SearchIcon className="w-6 h-6" />}
                className="my-3"
              />

              <MHTextInput
                id="employerName"
                type="text"
                placeholder="Employer Name"
                value={enteredEmployer}
                onChange={employerNameInputChangeHandler}
                onBlur={employerNameInputBlurHandler}
                required
                startAdornment={<SearchIcon className="w-6 h-6" />}
                className="my-3"
              />

              <MHTextInput
                id="employeeName"
                type="text"
                placeholder="Employee Name"
                value={enteredEmployee}
                onChange={employeeNameInputChangeHandler}
                onBlur={employeeNameInputBlurHandler}
                required
                startAdornment={<SearchIcon className="w-6 h-6" />}
                className="my-3"
              />

              {/* <MHSelect
                // label="Select Role"
                placeholder="Select Role"
                options={HouseholdRoles}
                value={enteredRole}
                onChange={(val) =>
                  contractRoleInputChangeHandler(val as string)
                }
                onBlur={contractRoleInputBlurHandler}
                // error={resolveErrorMessage(enteredBankNameHasError)(
                //   "Please select a bank"
                // )}
              /> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Contract;
