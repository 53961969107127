import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as DotLineIcon } from "../../../static/svg/dotline.svg";
import { ReactComponent as CalenderIcon } from "../../../static/svg/calendar.svg";
import RoundedLogoIcon from "../../../theme/icons/RoundedLogo";

type Props = {
  icon?: React.ReactNode | any;
  title?: string;
  count?: number | any;
  color?: string;
  hideVisibility?: boolean;
  state?: boolean;
  showState?: boolean;
  autoHeight?: boolean;
  startDate?: string;
  endDate?: string;
};

const TextCard = (props: Props) => {
  // console.log("icon", props.icon);
  return (
    <Box
      sx={{
        width: { sm: "220px", md: "250px" },
        height: props.autoHeight === false ? "100px" : "125px",
        border: "1px solid #E8E8E8",
        borderRadius: "8px",
        backgroundColor: props.color,
        justifyContent: "center",
        marginY: "10px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          //   justifyContent: "center",
          alignItems: "center",
          gap: 1,
          paddingTop: "20px",
          paddingBottom: props.icon?.ref === null ? "-20px" : "10px",
          paddingLeft: "20px",
        }}
      >
        {props.icon}
        <Typography
          variant="body1"
          fontSize={"12px"}
          sx={{
            color: "#194049",
          }}
        >
          {props.title}
        </Typography>

        {/* {props.state && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              variant="body1"
              fontSize={"12px"}
              sx={{
                color: "#194049",
              }}
            >
              active
            </Typography>

            <CaretDownIcon
              className=" cursor-pointer"
              onClick={() => console.log("I'm clicked")}
            />
          </Box>
        )} */}
      </Box>

      <Typography
        variant="subtitle1"
        fontSize={{ xs: "20px", sm: "22px", md: "26px" }}
        sx={{
          color: "#194049",
          paddingX: props.icon?.ref === null ? "75px" : "20px",
        }}
      >
        {props.hideVisibility === false ? "********" : props.count}
      </Typography>

      {props.showState && (
        <Box
          sx={{
            width: { sm: "80px", md: "76px" },
            height: "65px",
            border: "1px solid #FAF2F6",
            borderRadius: "8px",
            backgroundColor: "#FBFBFB",
            justifyContent: "center",
            // marginY: "10px",
            marginTop: "-5px",
            position: "absolute",
            top: "20px",
            right: "10px",
            // textAlign: "start",
          }}
        >
          <RoundedLogoIcon
            sx={{
              // p: 0.6,
              mt: 0.8,
              mb: 0.5,
              backgroundColor: "#D2E0CB",
              width: "20px",
              height: "20px",
              marginX: "auto",
            }}
          >
            <CalenderIcon className="w-2 h-2 " />
          </RoundedLogoIcon>

          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DotLineIcon className="w-fit h-6 " />

            <Box>
              <Typography
                variant="body1"
                fontSize={"8px"}
                sx={{
                  color: "#BBC5C9",
                  mb: 0.5,
                }}
              >
                {props.startDate}
              </Typography>

              <Typography
                variant="body1"
                fontSize={"8px"}
                sx={{
                  color: "#BBC5C9",
                }}
              >
                {props.endDate}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TextCard;
