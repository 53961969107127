import { SelectOption } from '@mui/base';
import { SxProps, Theme } from '@mui/material/styles';

export const DRAWER_WIDTH: number = 220;

export const MAX_CHART_PLOT_POINTS = 5;

export const DEFAULT_NOTIFICATION_DURATION: number = 6000;

export const EMAIL_FROM = 'noreply@caringblocks.com';


export const GENDER: SelectOption<string>[] = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
];

export const TrainingType: SelectOption<string>[] = [
  {
    value: "Online",
    label: "Online",
  },
  {
    value: "In-person",
    label: "In-person",
  },
  {
    value: "On the job",
    label: "On the job",
  },
];

export const BillingType: SelectOption<string>[] = [
  {
    value: "HealthAssessment",
    label: "Health Assessment",
  },
  {
    value: "Training",
    label: "Training",
  },
  {
    value: "BackgroundCheck",
    label: "Background Check",
  },
  {
    value: "HMO",
    label: "HMO",
  },
];

export const TrainingLevel: SelectOption<string>[] = [
  {
    value: "Onboard",
    label: "Onboard",
  },
  {
    value: "Level 1",
    label: "Level 1",
  },
  {
    value: "Level 2",
    label: "Level 2",
  },
 
];



export const ALLOCATION_FIELDS = [
  'Wellbeing',
  'Learning & Development',
  'Caregiving',
  'Remote & Hybrid',
  'Rewards & Recognition',
  'Family Building'
];

export const Subscription_Monthly_plans = [
  {
    id: 0,
    title: "Household",
    description: "",
    linkTo: "auth/onboard",
    amount: 5000,
    benefits: [
      "Onboarding Support",
      "Payroll & Contracts",
      "Document Control",
      "Basic Household Training",
    ],
  },
  {
    id: 1,
    title: "Household Pro",
    description: "",
    linkTo: "auth/onboard",
    amount: 7500,
    benefits: [
      "All Household features",
      "Advanced training",
      "24/7 Care Concierge",
      "Hiring support",
    ],
  },
  {
    id: 2,
    title: "Enterprise",
    description: "(perfect for businesses and corporations as a benefit)",
    linkTo: "https://calendly.com/caringbloc/thecaregap",
    amount: 0,
    benefits: [
        "All Household Pro features",
        "Personalized consultation",
        "Bulk Employee enrollment",
        "Employee Benefit Integration",
    ],
  }
]

export const Subscription_Quarterly_plans = [
  {
    id: 0,
    title: "Household",
    description: "",
    linkTo: "auth/onboard",
    amount: 13500,
    benefits: [
      "Onboarding Support",
      "Payroll & Contracts",
      "Document Control",
      "Basic Household Training",
    ],
  },
  {
    id: 1,
    title: "Household Pro",
    description: "",
    linkTo: "auth/onboard",
    amount: 20000,
    benefits: [
      "All Household features",
      "Advanced training",
      "24/7 Care Concierge",
      "Hiring support",
    ],
  },
  {
    id: 2,
    title: "Enterprise",
    description: "(perfect for businesses and corporations as a benefit)",
    linkTo: "https://calendly.com/caringbloc/thecaregap",
    amount: 0,
    benefits: [
        "All Household Pro features",
        "Personalized consultation",
        "Bulk Employee enrollment",
        "Employee Benefit Integration",
    ],
  }
]
export const Subscription_Yearly_plans = [
  {
    id: 0,
    title: "Household",
    description: "",
    linkTo: "auth/onboard",
    amount: 50000,
    benefits: [
      "Onboarding Support",
      "Payroll & Contracts",
      "Document Control",
      "Basic Household Training",
    ],
  },
  {
    id: 1,
    title: "Household Pro",
    description: "",
    linkTo: "auth/onboard",
    amount: 75000,
    benefits: [
      "All Household features",
      "Advanced training",
      "24/7 Care Concierge",
      "Hiring support",
    ],
  },
  {
    id: 2,
    title: "Enterprise",
    description: "(perfect for businesses and corporations as a benefit)",
    linkTo: "https://calendly.com/caringbloc/thecaregap",
    amount: 0,
    benefits: [
        "All Household Pro features",
        "Personalized consultation",
        "Bulk Employee enrollment",
        "Employee Benefit Integration",
    ],
  }
]



export const BACKGROUND_IMAGE_SX: SxProps<Theme> = {
  backgroundRepeat: 'no-repeat',
  backgroundColor: 'background.default',
  backgroundPosition: 'center',
  backgroundSize: 'cover'
};

export const ROLES: SelectOption<string>[] = [
  {
    value: 'Admin',
    label: 'Admin'
  },
  // {
  //   value: 'Member',
  //   label: 'Member'
  // }
];

export const RELATIONSHIP_STATUS_OPTIONS: SelectOption<string>[] = [
  {
    value: 'single',
    label: 'Single'
  },
  {
    value: 'married',
    label: 'Married'
  },
  {
    value: 'committed',
    label: 'Committed'
  }
];

export const QUANTITY_OPTIONS: SelectOption<string>[] = [
  {
    value: '0',
    label: '0'
  },
  {
    value: '1',
    label: '1'
  },
  {
    value: '2',
    label: '2'
  },
  {
    value: '3',
    label: '3'
  },
  {
    value: '4',
    label: '4'
  },
  {
    value: '5+',
    label: '5+'
  }
];

export const BOOL_OPTIONS: SelectOption<string>[] = [
  {
    value: 'yes',
    label: 'Yes'
  },
  {
    value: 'no',
    label: 'No'
  }
];

export const IDENTITY_OPTIONS: SelectOption<string>[] = [
  {
    value: 'she/her',
    label: 'She/Her'
  },
  {
    value: 'he/him',
    label: 'He/Him'
  },
  {
    value: 'they/them',
    label: 'They/Them'
  },
  {
    value: 'other',
    label: 'Other'
  }
];

export const RACE_OPTIONS: SelectOption<string>[] = [
  {
    value: 'Native American',
    label: 'Native American'
  },
  {
    value: 'Asian',
    label: 'Asian'
  },
  {
    value: 'Black or African American',
    label: 'Black or African American'
  },
  {
    value: 'Native Hawaiian or Other Pacific Islander',
    label: 'Native Hawaiian or Other Pacific Islander'
  },
  {
    value: 'Hispanic, Latino, or Spanish origin',
    label: 'Hispanic, Latino, or Spanish origin'
  },
  {
    value: 'White',
    label: 'White'
  },
  {
    value: 'Unknown',
    label: 'Unknown'
  },
  {
    value: 'Other/Prefer to self-describe',
    label: 'Other/Prefer to self-describe'
  },
  {
    value: "Don't wish to answer",
    label: "Don't wish to answer"
  }
];

export const CARE_RESPONSIBILITY_OPTIONS: SelectOption<string>[] = [
  {
    value: 'self',
    label: 'Self'
  },
  {
    value: 'child',
    label: 'Child'
  },
  {
    value: 'parent',
    label: 'Parent'
  },
  {
    value: 'extended_family',
    label: 'Extended family'
  },
  {
    value: 'family_of_choice',
    label: 'Family of choice'
  },
  {
    value: 'household',
    label: 'Household'
  },
  {
    value: 'pet',
    label: 'Pet'
  }
];


export const BANKS_NAME: SelectOption<string>[] = [
  {
    value: 'Zenith Bank',
    label: 'Zenith Bank'
  },
  {
    value: 'First Bank',
    label: 'First Bank'
  },
  {
    value: 'Wema Bank',
    label: 'Wema Bank'
  },
  {
    value: 'Access Bank',
    label: 'Access Bank'
  },
  {
    value: 'GTBank',
    label: 'GTBank'
  },
];

export const CALENDAR_TYPES: Array<SelectOption<string>> = [
  {
    label: 'Google Calendar',
    value: 'GOOGLE'
  },
  {
    label: 'Outlook',
    value: 'OUTLOOK'
  },
  {
    label: 'Apple Calendar',
    value: 'APPLE'
  }
];

const FILE_TYPES: Array<string> = [
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
  'image/x-icon'
];


// Define the type for SelectOption
interface StateSelectOption<T> {
  state: T;
  lcda: T[];
}


export const NIGERIA_STATES_LCDAS: StateSelectOption<string>[] = [
  { state: "Abia", lcda: ["Aba North", "Aba South", "Arochukwu", "Bende", "Ikwuano", "Isiala Ngwa North", "Isiala Ngwa South", "Isuikwuato", "Obi Ngwa", "Ohafia", "Osisioma Ngwa", "Ugwunagbo", "Ukwa East", "Ukwa West", "Umuahia North", "Umuahia South", "Umu Nneochi"] },
  { state: "Adamawa", lcda: ["Demsa", "Fufore", "Ganye", "Girei", "Gombi", "Guyuk", "Hong", "Jada", "Lamurde", "Madagali", "Maiha", "Mayo Belwa", "Michika", "Mubi North", "Mubi South", "Numan", "Shelleng", "Song", "Toungo", "Yola North", "Yola South"] },
  { state: "Akwa Ibom", lcda: ["Abak", "Eastern Obolo", "Eket", "Esit Eket", "Essien Udim", "Etim Ekpo", "Etinan", "Ibeno", "Ibesikpo Asutan", "Ibiono Ibom", "Ika", "Ikono", "Ikot Abasi", "Ikot Ekpene", "Ini", "Itu", "Mbo", "Mkpat Enin", "Nsit Atai", "Nsit Ibom", "Nsit Ubium", "Obot Akara", "Okobo", "Onna", "Oron", "Oruk Anam", "Udung Uko", "Ukanafun", "Uruan", "Urue-Offong/Oruko", "Uyo"] },
  { state: "Anambra", lcda: ["Aguata", "Anambra East", "Anambra West", "Anaocha", "Awka North", "Awka South", "Ayamelum", "Dunukofia", "Ekwusigo", "Idemili North", "Idemili South", "Ihiala", "Njikoka", "Nnewi North", "Nnewi South", "Ogbaru", "Onitsha North", "Onitsha South", "Orumba North", "Orumba South", "Oyi"] },
  { state: "Bauchi", lcda: ["Alkaleri", "Bauchi", "Bogoro", "Damban", "Darazo", "Dass", "Gamawa", "Ganjuwa", "Giade", "Itas/Gadau", "Jama'are", "Katagum", "Kirfi", "Misau", "Ningi", "Shira", "Tafawa Balewa", "Toro", "Warji", "Zaki"] },
  { state: "Bayelsa", lcda: ["Brass", "Ekeremor", "Kolokuma/Opokuma", "Nembe", "Ogbia", "Sagbama", "Southern Ijaw", "Yenagoa"] },
  { state: "Benue", lcda: ["Ado", "Agatu", "Apa", "Buruku", "Gboko", "Guma", "Gwer East", "Gwer West", "Katsina-Ala", "Konshisha", "Kwande", "Logo", "Makurdi", "Obi", "Ogbadibo", "Oju", "Okpokwu", "Ohimini", "Oturkpo", "Tarka", "Ukum", "Ushongo", "Vandeikya"] },
  { state: "Borno", lcda: ["Abadam", "Askira/Uba", "Bama", "Bayo", "Biu", "Chibok", "Damboa", "Dikwa", "Gubio", "Guzamala", "Gwoza", "Hawul", "Jere", "Kaga", "Kala/Balge", "Konduga", "Kukawa", "Kwaya Kusar", "Mafa", "Magumeri", "Maiduguri", "Marte", "Mobbar", "Monguno", "Ngala", "Nganzai", "Shani"] },
  { state: "Cross River", lcda: ["Abi", "Akamkpa", "Akpabuyo", "Bakassi", "Bekwarra", "Biase", "Boki", "Calabar Municipal", "Calabar South", "Etung", "Ikom", "Obanliku", "Obubra", "Obudu", "Odukpani", "Ogoja", "Yakurr", "Yala"] },
  { state: "Delta", lcda: ["Aniocha North", "Aniocha South", "Bomadi", "Burutu", "Ethiope East", "Ethiope West", "Ika North East", "Ika South", "Isoko North", "Isoko South", "Ndokwa East", "Ndokwa West", "Okpe", "Oshimili North", "Oshimili South", "Patani", "Sapele", "Udu", "Ughelli North", "Ughelli South", "Ukwuani", "Uvwie", "Warri North", "Warri South", "Warri South West"] },
  { state: "Ebonyi", lcda: ["Abakaliki", "Afikpo North", "Afikpo South (Edda)", "Ebonyi", "Ezza North", "Ezza South", "Ikwo", "Ishielu", "Ivo", "Izzi", "Ohaozara", "Ohaukwu", "Onicha"] },
  { state: "Edo", lcda: ["Akoko-Edo", "Egor", "Esan Central", "Esan North-East", "Esan South-East", "Esan West", "Etsako Central", "Etsako East", "Etsako West", "Igueben", "Ikpoba-Okha", "Orhionmwon", "Oredo", "Ovire North-East", "Owan East", "Owan West", "Uhunmwonde"] },
  { state: "Ekiti", lcda: ["Ado-Ekiti", "Efon", "Ekiti East", "Ekiti South-West", "Ekiti West", "Emure", "Gbonyin", "Ido/Osi", "Ijero", "Ikere", "Ikole", "Ilejemeje", "Irepodun/Ifelodun", "Ise/Orun", "Moba", "Oye"] },
  { state: "Enugu", lcda: ["Aninri", "Awgu", "Enugu East", "Enugu North", "Enugu South", "Ezeagu", "Igbo Etiti", "Igbo Eze North", "Igbo Eze South", "Isi Uzo", "Nkanu East", "Nkanu West", "Nsukka", "Oji River", "Udenu", "Udi", "Uzo Uwani"] },
  { state: "FCT - Abuja", lcda: ["Abaji", "Bwari", "Gwagwalada", "Kuje", "Kwali", "Municipal Area Council"] },
  { state: "Gombe", lcda: ["Akko", "Balanga", "Billiri", "Dukku", "Funakaye", "Gombe", "Kaltungo", "Kwami", "Nafada", "Shongom", "Yamaltu/Deba"] },
  { state: "Imo", lcda: ["Aboh Mbaise", "Ahiazu Mbaise", "Ehime Mbano", "Ezinihitte", "Ideato North", "Ideato South", "Ihitte/Uboma", "Ikeduru", "Isiala Mbano", "Isu", "Mbaitoli", "Ngor Okpala", "Njaba", "Nkwerre", "Nwangele", "Obowo", "Oguta", "Ohaji/Egbema", "Okigwe", "Onuimo", "Orlu", "Orsu", "Oru East", "Oru West", "Owerri Municipal", "Owerri North", "Owerri West"] },
  { state: "Jigawa", lcda: ["Auyo", "Babura", "Biriniwa", "Birnin Kudu", "Buji", "Dutse", "Gagarawa", "Garki", "Gumel", "Guri", "Gwaram", "Gwiwa", "Hadejia", "Jahun", "Kafin Hausa", "Kaugama", "Kazaure", "Kiri Kasama", "Kiyawa", "Maigatari", "Malam Madori", "Miga", "Ringim", "Roni", "Sule Tankarkar", "Taura", "Yankwashi"] },
  { state: "Kaduna", lcda: ["Birnin Gwari", "Chikun", "Giwa", "Igabi", "Ikara", "Jaba", "Jema'a", "Kachia", "Kaduna North", "Kaduna South", "Kagarko", "Kajuru", "Kaura", "Kauru", "Kubau", "Kudan", "Lere", "Makarfi", "Sabon Gari", "Sanga", "Soba", "Zangon Kataf", "Zaria"] },
  { state: "Kano", lcda: ["Ajingi", "Albasu", "Bagwai", "Bebeji", "Bichi", "Bunkure", "Dala", "Dambatta", "Dawakin Kudu", "Dawakin Tofa", "Doguwa", "Fagge", "Gabasawa", "Garko", "Garun Mallam", "Gaya", "Gezawa", "Gwale", "Gwarzo", "Kabo", "Kano Municipal", "Karaye", "Kibiya", "Kiru", "Kumbotso", "Kunchi", "Kura", "Madobi", "Makoda", "Minjibir", "Nasarawa", "Rano", "Rimin Gado", "Rogo", "Shanono", "Sumaila", "Takai", "Tarauni", "Tofa", "Tsanyawa", "Tudun Wada", "Ungogo", "Warawa", "Wudil"] },
  { state: "Katsina", lcda: ["Bakori", "Batagarawa", "Batsari", "Baure", "Bindawa", "Charanchi", "Dan Musa", "Dandume", "Danja", "Daura", "Dutsi", "Dutsin Ma", "Faskari", "Funtua", "Ingawa", "Jibia", "Kafur", "Kaita", "Kankara", "Kankia", "Katsina", "Kurfi", "Kusada", "Mai'Adua", "Malumfashi", "Mani", "Mashi", "Matazu", "Musawa", "Rimi", "Sabuwa", "Safana", "Sandamu", "Zango"] },
  { state: "Kebbi", lcda: ["Aleiro", "Arewa Dandi", "Argungu", "Augie", "Bagudo", "Birnin Kebbi", "Bunza", "Dandi", "Fakai", "Gwandu", "Jega", "Kalgo", "Koko/Besse", "Maiyama", "Ngaski", "Sakaba", "Shanga", "Suru", "Wasagu/Danko", "Yauri", "Zuru"] },
  { state: "Kogi", lcda: ["Adavi", "Ajaokuta", "Ankpa", "Bassa", "Dekina", "Ibaji", "Idah", "Igalamela Odolu", "Ijumu", "Kabba/Bunu", "Kogi", "Lokoja", "Mopa Muro", "Ofu", "Ogori/Magongo", "Okehi", "Okene", "Olamaboro", "Omala", "Yagba East", "Yagba West"] },
  { state: "Kwara", lcda: ["Asa", "Baruten", "Edu", "Ekiti", "Ifelodun", "Ilorin East", "Ilorin South", "Ilorin West", "Irepodun", "Isin", "Kaiama", "Moro", "Offa", "Oke Ero", "Oyun", "Pategi"] },
  { state: "Lagos", lcda: ["Agege", "Ajeromi-Ifelodun", "Alimosho", "Amuwo-Odofin", "Apapa", "Badagry", "Epe", "Eti Osa", "Ibeju-Lekki", "Ifako-Ijaiye", "Ikeja", "Ikorodu", "Kosofe", "Lagos Island", "Lagos Mainland", "Mushin", "Ojo", "Oshodi-Isolo", "Shomolu", "Surulere"] },
  { state: "Nasarawa", lcda: ["Akwanga", "Awe", "Doma", "Karu", "Keana", "Keffi", "Kokona", "Lafia", "Nasarawa", "Nasarawa Egon", "Obi", "Toto", "Wamba"] },
  { state: "Niger", lcda: ["Agaie", "Agwara", "Bida", "Borgu", "Bosso", "Chanchaga", "Edati", "Gbako", "Gurara", "Katcha", "Kontagora", "Lapai", "Lavun", "Magama", "Mariga", "Mokwa", "Muya", "Paikoro", "Rafi", "Rijau", "Shiroro", "Suleja", "Tafa", "Wushishi"] },
  { state: "Ogun", lcda: ["Abeokuta North", "Abeokuta South", "Ado-Odo/Ota", "Ewekoro", "Ifo", "Ijebu East", "Ijebu North", "Ijebu North East", "Ijebu Ode", "Ikenne", "Imeko Afon", "Ipokia", "Obafemi Owode", "Odeda", "Odogbolu", "Ogun Waterside", "Remo North", "Sagamu", "Yewa North", "Yewa South"] },
  { state: "Ondo", lcda: ["Akoko North-East", "Akoko North-West", "Akoko South-East", "Akoko South-West", "Akure North", "Akure South", "Ese Odo", "Idanre", "Ifedore", "Ilaje", "Ile Oluji/Okeigbo", "Irele", "Odigbo", "Okitipupa", "Ondo East", "Ondo West", "Ose", "Owo"] },
  { state: "Osun", lcda: ["Aiyedaade", "Aiyedire", "Atakumosa East", "Atakumosa West", "Boluwaduro", "Boripe", "Ede North", "Ede South", "Egbedore", "Ejigbo", "Ife Central", "Ife East", "Ife North", "Ife South", "Ifedayo", "Ifelodun", "Ila", "Ilesa East", "Ilesa West", "Irepodun", "Irewole", "Isokan", "Iwo", "Obokun", "Odo Otin", "Ola Oluwa", "Olorunda", "Oriade", "Orolu", "Osogbo"] },
  { state: "Oyo", lcda: ["Afijio", "Akinyele", "Atiba", "Atisbo", "Egbeda", "Ibadan North", "Ibadan North-East", "Ibadan North-West", "Ibadan South-East", "Ibadan South-West", "Ibarapa Central", "Ibarapa East", "Ibarapa North", "Ido", "Irepo", "Iseyin", "Itesiwaju", "Iwajowa", "Kajola", "Lagelu", "Ogo Oluwa", "Ogbomosho North", "Ogbomosho South", "Olorunsogo", "Oluyole", "Ona Ara", "Orelope", "Ori Ire", "Oyo East", "Oyo West", "Saki East", "Saki West", "Surulere"] },
  { state: "Plateau", lcda: ["Barkin Ladi", "Bassa", "Bokkos", "Jos East", "Jos North", "Jos South", "Kanam", "Kanke", "Langtang North", "Langtang South", "Mangu", "Mikang", "Pankshin", "Qua'an Pan", "Riyom", "Shendam", "Wase"] },
  { state: "Rivers", lcda: ["Abua/Odual", "Ahoada East", "Ahoada West", "Akuku-Toru", "Andoni", "Asari-Toru", "Bonny", "Degema", "Emohua", "Eleme", "Etche", "Gokana", "Ikwerre", "Khana", "Obio/Akpor", "Ogba/Egbema/Ndoni", "Ogu/Bolo", "Okrika", "Omuma", "Opobo/Nkoro", "Oyigbo", "Port Harcourt", "Tai"] },
  { state: "Sokoto", lcda: ["Binji", "Bodinga", "Dange Shuni", "Gada", "Goronyo", "Gudu", "Gwadabawa", "Illela", "Kebbe", "Kware", "Rabah", "Sabon Birni", "Shagari", "Silame", "Sokoto North", "Sokoto South", "Tambuwal", "Tangaza", "Tureta", "Wamako", "Wurno", "Yabo"] },
  { state: "Taraba", lcda: ["Ardo Kola", "Bali", "Donga", "Gashaka", "Gassol", "Ibi", "Jalingo", "Karim Lamido", "Kurmi", "Lau", "Sardauna", "Takum", "Ussa", "Wukari", "Yorro", "Zing"] },
  { state: "Yobe", lcda: ["Bade", "Bursari", "Damaturu", "Fika", "Fune", "Geidam", "Gujba", "Gulani", "Jakusko", "Karasuwa", "Machina", "Nangere", "Nguru", "Potiskum", "Tarmuwa", "Yunusari", "Yusufari"] },
  { state: "Zamfara", lcda: ["Anka", "Bakura", "Birnin Magaji/Kiyaw", "Bukkuyum", "Bungudu", "Chafe", "Gummi", "Gusau", "Kaura Namoda", "Maradun", "Maru", "Shinkafi", "Talata Mafara", "Zurmi"] }
];



export const testTraining = [
 
  {
    id: 1,
    level: 1,
    trainingMode: "on-the-job",
    title:"Introduction to Childcare    ",
    date: "2024-03-19T17:01:22.781162Z",
    startTime: "2024-03-19T12:01:22.781162Z",
    endTime: "2024-03-19T17:01:22.781162Z",
    category: "nanny",
    status: "unattended",
    price: 3500,
    imgSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkvVQd30vXF5OnTMGBrg-OMMA3pSkPuGbYhQ&s",
    objectives:[
      
      "Wellbeing & Self awareness",
      "Learning & Development",
      "Caregiving Fundamentals",
      "Remote & Hybrid",
      "Rewards & Recognition",
      "Family Building",
                              
    ]
  },
  {
    id: 2,
    level: 3,
    trainingMode: "online",
    title:"Introduction to Housekeeping and Meal Prep",
    date: "2024-05-19T17:01:22.781162Z",
    startTime: "2024-03-19T12:01:22.781162Z",
    endTime: "2024-03-19T17:01:22.781162Z",
    category: "Chef/Cook, nanny",
    status: "assigned",
    price: 0,
    imgSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTY3G1sXqhocSOxEwv6IefO3ecvQjvGdhMRFQ&s",

    objectives:[
      
      "Wellbeing & Self awareness",
      "Learning & Development",
      "Caregiving Fundamentals",
      "Remote & Hybrid",
      "Rewards & Recognition",
      "Family Building",
                              
    ]

  },
  {
    id: 3,
    level: 2,
    trainingMode: "online",
    title:"Hands-On Housekeeping and Meal Prep",
    date: "2024-05-19T17:01:22.781162Z",
    startTime: "2024-03-19T12:01:22.781162Z",
    endTime: "2024-03-19T17:01:22.781162Z",
    category: "Chef/Cook",
    status: "assigned",
    price: 0,
    imgSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRP_pf0jRbGLeZboP5XmRiAyVERhNUAuuLX8A&s",
    objectives:[
      
      "Wellbeing & Self awareness",
      "Learning & Development",
      "Caregiving Fundamentals",
      "Remote & Hybrid",
      "Rewards & Recognition",
      "Family Building",
                              
    ]

  },
  {
    id: 4,
    level: 1,
    trainingMode: "in-person",
    title:"Environment Awareness",
    date: "2024-05-22T17:01:22.781162Z",
    startTime: "2024-03-19T12:01:22.781162Z",
    endTime: "2024-03-19T17:01:22.781162Z",
    category: "nanny, Driver/Chauffeur",
    status: "assigned",
    price: 0,
    imgSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdjgwHPzXMQNYDHYQX4EvCy5Qn47OnZj2BKw&s",
    objectives:[
      
      "Wellbeing & Self awareness",
      "Learning & Development",
      "Caregiving Fundamentals",
      "Remote & Hybrid",
      "Rewards & Recognition",
      "Family Building",
                              
    ]
  },
  {
    id: 5,
    level: 2,
    trainingMode: "in-person",
    title:"Hands-On Driving Skills",
    date: "2024-05-22T17:01:22.781162Z",
    startTime: "2024-03-19T12:01:22.781162Z",
    endTime: "2024-03-19T17:01:22.781162Z",
    category: "Driver/Chauffeur",
    status: "completed",
    price: 10000,
    imgSrc: "https://media.licdn.com/dms/image/D4E22AQHM9V2gh0gdQg/feedshare-shrink_800/0/1713882016047?e=1717632000&v=beta&t=qwUjlo4spoGPLcfGSMovJJVsuKqZ_SuwJFJxiev8w88",
    objectives:[
      
      "Wellbeing & Self awareness",
      "Learning & Development",
      "Caregiving Fundamentals",
      "Remote & Hybrid",
      "Rewards & Recognition",
      "Family Building",
                              
    ]
  },
  {
    id: 6,
    level: 1,
    trainingMode: "online",
    title:"Basic Childcare Techniques",
    date: "2024-03-19T17:01:22.781162Z",
    startTime: "2024-03-19T12:01:22.781162Z",
    endTime: "2024-03-19T17:01:22.781162Z",
    category: "nanny",
    status: "completed",
    price: 0,
    imgSrc: "https://media.licdn.com/dms/image/D4E22AQHM9V2gh0gdQg/feedshare-shrink_800/0/1713882016047?e=1717632000&v=beta&t=qwUjlo4spoGPLcfGSMovJJVsuKqZ_SuwJFJxiev8w88",
    objectives:[
      
      "Wellbeing & Self awareness",
      "Learning & Development",
      "Caregiving Fundamentals",
      "Remote & Hybrid",
      "Rewards & Recognition",
      "Family Building",
                              
    ]
  },
  {
    id: 7,
    level: 1,
    trainingMode: "in-person",
    title:"Basic Childcare Techniques",
    date: "2024-03-19T17:01:22.781162Z",
    startTime: "2024-03-19T12:01:22.781162Z",
    endTime: "2024-03-19T17:01:22.781162Z",
    category: "nanny",
    status: "completed",
    price: 0,
    imgSrc: "https://media.licdn.com/dms/image/D4E22AQHM9V2gh0gdQg/feedshare-shrink_800/0/1713882016047?e=1717632000&v=beta&t=qwUjlo4spoGPLcfGSMovJJVsuKqZ_SuwJFJxiev8w88",
    objectives:[
      
      "Wellbeing & Self awareness",
      "Learning & Development",
      "Caregiving Fundamentals",
      "Remote & Hybrid",
      "Rewards & Recognition",
      "Family Building",
                              
    ]
  },
  {
    id: 8,
    level: 1,
    trainingMode: "on-the-job",
    title:"Basic Childcare Techniques",
    date: "2024-03-19T17:01:22.781162Z",
    startTime: "2024-03-19T12:01:22.781162Z",
    endTime: "2024-03-19T17:01:22.781162Z",
    category: "nanny",
    status: "completed",
    price: 9000,
    imgSrc: "https://media.licdn.com/dms/image/D4E22AQHM9V2gh0gdQg/feedshare-shrink_800/0/1713882016047?e=1717632000&v=beta&t=qwUjlo4spoGPLcfGSMovJJVsuKqZ_SuwJFJxiev8w88",
    objectives:[
      
      "Wellbeing & Self awareness",
      "Learning & Development",
      "Caregiving Fundamentals",
      "Remote & Hybrid",
      "Rewards & Recognition",
      "Family Building",
                              
    ]
  },
  {
    id: 9,
    level: 1,
    trainingMode: "on-the-job",
    title:"Basic Eldercare Techniques",
    date: "2024-03-19T17:01:22.781162Z",
    startTime: "2024-03-19T12:01:22.781162Z",
    endTime: "2024-03-19T17:01:22.781162Z",
    category: "nanny",
    status: "completed",
    price: 9000,
    imgSrc: "https://media.licdn.com/dms/image/D4E22AQHM9V2gh0gdQg/feedshare-shrink_800/0/1713882016047?e=1717632000&v=beta&t=qwUjlo4spoGPLcfGSMovJJVsuKqZ_SuwJFJxiev8w88",
    objectives:[
      
      "Wellbeing & Self awareness",
      "Learning & Development",
      "Caregiving Fundamentals",
      "Remote & Hybrid",
      "Rewards & Recognition",
      "Family Building",
                              
    ]
  },
  {
    id: 10,
    level: 1,
    trainingMode: "online",
    title:"Basic Eldercare Techniques",
    date: "2024-03-19T17:01:22.781162Z",
    startTime: "2024-03-19T12:01:22.781162Z",
    endTime: "2024-03-19T17:01:22.781162Z",
    category: "nanny",
    status: "completed",
    price: 9000,
    imgSrc: "https://media.licdn.com/dms/image/D4E22AQHM9V2gh0gdQg/feedshare-shrink_800/0/1713882016047?e=1717632000&v=beta&t=qwUjlo4spoGPLcfGSMovJJVsuKqZ_SuwJFJxiev8w88",
    objectives:[
      
      "Wellbeing & Self awareness",
      "Learning & Development",
      "Caregiving Fundamentals",
      "Remote & Hybrid",
      "Rewards & Recognition",
      "Family Building",
                              
    ]
  },

]


export const employeeTestArray = [
  {
    title: "Medical History & Physical Examination",
    type: "health",
    date: "March 15,2024",
  },
  {
    title: "Child Abuse Registry Check",
    type: "bgcheck",
    date: "February 15,2024",
  },
  {
    title: "Communicable Disease Screening",
    type: "health",
    date: "May 22,2024",
  },
  {
    title: "Criminal Records Registry Check",
    type: "bgcheck",
    date: "June 12,2024",
  },
  { title: "Vision Screening Test", type: "health", date: "Aug 2,2024" },
  { title: "Driver's Road Test", type: "bgcheck", date: "Aug 23,2024" },
];

export const assessmentData = [
  {
    id: 1,
    label: "Medical History & Physical Examination  (via HealthTracka)",
    handleView: () => console.log("Viewing January Payslip"),
    handleDownload: () => console.log("Downloading January Payslip"),
    date: "Mar 20,2024",
  },
  {
    id: 2,
    label: "Communicable Disease Screening  (via HealthTracka)",
    handleView: () => console.log("Viewing February Payslip"),
    handleDownload: () => console.log("Downloading February Payslip"),
    date: "Mar 20,2024",
  },
  {
    id: 3,
    label: "Drug And Alcohol Test",
    handleView: () => console.log("Viewing March Payslip"),
    handleDownload: () => console.log("Downloading March Payslip"),
    date: "Mar 20,2024",
  },
  {
    id: 4,
    label: "Medical History & Physical Examination  (via HealthTracka)",
    handleView: () => console.log("Viewing April Payslip"),
    handleDownload: () => console.log("Downloading April Payslip"),
    date: "Mar 20,2024",
  },
  {
    id: 5,
    label: "Medical History & Physical Examination  (via HealthTracka) 2",
    handleView: () => console.log("Viewing January Payslip"),
    handleDownload: () => console.log("Downloading January Payslip"),
    date: "Mar 20,2024",
  },
  {
    id: 6,
    label: "Communicable Disease Screening  (via HealthTracka) 2",
    handleView: () => console.log("Viewing February Payslip"),
    handleDownload: () => console.log("Downloading February Payslip"),
    date: "Mar 20,2024",
  },
  {
    id: 7,
    label: "Drug And Alcohol Test 2",
    handleView: () => console.log("Viewing March Payslip"),
    handleDownload: () => console.log("Downloading March Payslip"),
    date: "Mar 20,2024",
  },
  {
    id: 8,
    label: "Medical History & Physical Examination  (via HealthTracka) 2",
    handleView: () => console.log("Viewing April Payslip"),
    handleDownload: () => console.log("Downloading April Payslip"),
    date: "Mar 20,2024",
  },
];



export const colorCodes = [
  {
    color: "#A0BDD2"
  },
  {
    color: "#F9BA52"
  },
  {
    color: "#AFCEA0"
  },
  {
    color: "#817EB9"
  },
  {
    color: "#C27171"
  },
  {
    color: "#D8E3EC"
  },
  {
    color: "#FEE4B9"
  },
  {
    color: "#DFE9DB"
  },
  {
    color: "#CAC9E3"
  },
  {
    color: "#D49C9C"
  },
  {
    color: "#C7D8E4"
  },
  {
    color: "#FFDA9C"
  },
  {
    color: "#D2E0CB"
  },
  {
    color: "#B4B2D7"
  },
  {
    color: "#C27171"
  },
  {
    color: "#194049"
  },
  {
    color: "#F8F246"
  },
  {
    color: "#FFFC9B"
  },
  {
    color: "#E3CEAE"
  },
  // {
  //   color: "#F3F4F6"
  // },
  ]
// Function to pick a random color code
export function getRandomColorCode() {
  const randomIndex = Math.floor(Math.random() * colorCodes.length);
  return colorCodes[randomIndex].color;
}