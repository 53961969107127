/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";

import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

// import SearchField from "../Common/Form/SearchField";
// import IconButtonUnstyled from "../Common/Button/IconButtonStyled";
import MHMenu, { MHMenuItem } from "../Common/MenuPopper/MHMenu";
// import MHPrimaryLogo from "../../theme/icons/MHPrimaryLogo";
import MHLogo, { MHStackedLogo } from "../../theme/icons/MHLogo";

import { ReactComponent as BellIcon } from "../../static/svg/bell.svg";
import { ReactComponent as MenuIcon } from "../../static/svg/menu.svg";

import { ReactComponent as CaretDownIcon } from "../../static/svg/caret-down.svg";
import AuthContext from "../../store/context/auth-context";
import DashboardContext from "../../store/context/dashboard.context";
import IconButtonStyled from "../Common/Button/IconButtonStyled";
import LayoutContext from "../../store/context/layout.context";
import { Badge, BadgeProps, useMediaQuery, useTheme } from "@mui/material";
import AbsolutePositionedContainer from "../Common/UI/AbsolutePositionedContainer";
import StepperBar from "./StepperBar";
import MHButton from "../Common/Button/MHButton";

export function AuthNavBar() {
  const { path } = useRouteMatch();

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  const EmployerNavItems = [
    { label: "DASHBOARD", link: `${path}/dashboard` },
    { label: "CONFIGURATION", link: `${path}/configuration` },
    // { label: "RESOURCES", link: `${path}/resources` },
  ];

  // const EmployeeNavItems = [
  //   { label: "DASHBOARD", link: `${path}/employee/dashboard` },
  //   { label: "PROFILE", link: `${path}/employee/profile` },
  //   { label: "TRAINING", link: `${path}/employee/training` },
  //   // { label: "RESOURCES", link: `${path}/resources` },
  // ];

  // const [NavItems, setNavItems] = React.useState(EmployerNavItems);

  // change user to user.role === Employer or Employee
  // to switch NavItems according to roles

  // React.useEffect(() => {
  //   if (authCtx?.role === "Employer" && authCtx.isAuthenticated) {
  //     setNavItems(EmployerNavItems);
  //   } else if (authCtx?.role === "Employee" && authCtx.isAuthenticated) {
  //     setNavItems(EmployeeNavItems);
  //   }
  // }, [authCtx]);

  return (
    <Stack
      direction="row"
      spacing={4}
      sx={{ width: "100%", justifyContent: "center" }}
      className="hidden lg:flex"
    >
      <ul
        style={{
          listStyleType: "none",
          display: "flex",
          alignItems: "center",
          padding: 0,
        }}
      >
        {EmployerNavItems.map((item, index) => (
          <li style={{ margin: "0 10px" }} key={index}>
            <MuiLink
              component={Link}
              // underline="always"
              display="block"
              align="center"
              to={item.link}
              className="removeUnderline cursor-pointer"
            >
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "10px", sm: "10px" }}
                letterSpacing={{ xs: "11.5%", sm: "11.5%" }}
                lineHeight={{ xs: "92%", sm: "92%" }}
                color="primary"
              >
                {item.label}
              </Typography>
            </MuiLink>
          </li>
        ))}
      </ul>
    </Stack>
  );
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBarStyled = styled(MuiAppBar, {
  shouldForwardProp: (prop: any) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1000,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -2,
    top: -5,
    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: "0 4px",
    // width: "20px",
    // height: "20px",
  },
}));

const AppBar = () => {
  const history = useHistory();
  const location = useLocation();
  let currentPath = location.pathname;
  // console.log("location", location.pathname);

  const authCtx = React.useContext(AuthContext);
  const { user, logout } = authCtx;

  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const layoutCtx = React.useContext(LayoutContext);
  const { isSidebarOpen, toggleSidebar } = layoutCtx;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const LG_ITEMS: MHMenuItem[] = [
    {
      label: "Login",
      onClick: () => {
        history.push("/auth/sign-in");
      },
    },
    {
      label: "Get Started",
      onClick: () => {
        history.push("/auth/onboard");
      },
    },
  ];

  const MENU_ITEMS: MHMenuItem[] = [
    {
      label: "Dashboard",
      onClick: () => {
        history.push("/operations/dashboard");
      },
    },
    {
      label: "Profile",
      onClick: () => {
        history.push("/operations/settings/profile");
      },
    },
    {
      label: "Settings",
      onClick: () => {
        history.push("/operations/settings/profile");
      },
    },
    {
      label: "Logout",
      onClick: logout,
    },
  ];
  return (
    <Box sx={{ flexGrow: 1, overflowX: "hidden" }}>
      <AppBarStyled position="fixed" open={true}>
        <Toolbar
          sx={{
            justifyContent: "space-between",
            position: "relative",
            px: { xs: 2 },
          }}
        >
          {matchesMdDown ? (
            <>
              {authCtx.isAuthenticated ? (
                <IconButtonStyled
                  onClick={
                    isSidebarOpen ? toggleSidebar(false) : toggleSidebar(true)
                  }
                  className={authCtx.isAuthenticated ? "block" : "hidden"}
                >
                  <MenuIcon />
                </IconButtonStyled>
              ) : (
                <div className="px-2" />
              )}
            </>
          ) : null}

          {matchesMdDown ? (
            <AbsolutePositionedContainer>
              <Box
                aria-label="menu"
                flexGrow={1}
                sx={{
                  // ml: 5,
                  "& > img": {
                    height: 100,
                    width: "auto",
                  },
                }}
              >
                {/* <MHLogo style={{ width: "10rem", margin: 0 }} /> */}
                <MuiLink
                  component={Link}
                  // underline="always"
                  display="block"
                  align="center"
                  to={"/"}
                  className="removeUnderline cursor-pointer"
                >
                  {currentPath.includes("operations") ? (
                    <MHLogo style={{ width: "12rem", margin: 0 }} />
                  ) : (
                    <MHStackedLogo
                      style={{
                        width: "65px",
                        margin: 0,
                        height: "65px",
                      }}
                    />
                  )}
                </MuiLink>
                {/* <img src={organization?.logo} alt={organization?.CompanyName} /> */}
              </Box>
            </AbsolutePositionedContainer>
          ) : (
            <>
              {currentPath.includes("sign-in") ||
              currentPath.includes("forgot-password") ||
              currentPath.includes("reset-password") ? (
                <AbsolutePositionedContainer>
                  <Box
                    aria-label="menu"
                    flexGrow={1}
                    sx={{
                      // ml: 5,
                      "& > img": {
                        height: 100,
                        width: "auto",
                      },
                    }}
                  >
                    <MuiLink
                      component={Link}
                      // underline="always"
                      display="block"
                      align="center"
                      to={"/"}
                      className="removeUnderline cursor-pointer"
                    >
                      <MHLogo style={{ width: "12rem", margin: 0 }} />
                    </MuiLink>
                    {/* <img src={organization?.logo} alt={organization?.CompanyName} /> */}
                  </Box>
                </AbsolutePositionedContainer>
              ) : (
                <Box
                  aria-label="menu"
                  flexGrow={1}
                  sx={{
                    ml: 5,
                    "& > img": {
                      height: 30,
                      width: "auto",
                    },
                  }}
                >
                  <MuiLink
                    component={Link}
                    // underline="always"
                    display="block"
                    align="center"
                    to={"/"}
                    className="removeUnderline cursor-pointer"
                  >
                    {currentPath.includes("operations") ? (
                      <MHLogo style={{ width: "12rem", margin: 0 }} />
                    ) : (
                      <MHStackedLogo
                        style={{
                          width: "65px",
                          margin: 0,
                          height: "65px",
                        }}
                      />
                    )}
                  </MuiLink>
                  {/* <img src={organization?.logo} alt={organization?.CompanyName} /> */}
                </Box>
              )}
            </>
          )}

          {/* Stepper for the top */}
          {/* {currentPath.includes("onboard") ? <StepperBar /> : null} */}

          {/* Navbar Items */}
          {authCtx.isAuthenticated && currentPath.includes("operations") ? (
            <AuthNavBar />
          ) : currentPath.includes("auth") ? null : null}

          <Stack
            direction="row"
            alignItems="center"
            spacing={6}
            // className={authCtx.isAuthenticated ? "block" : "hidden"}
          >
            {/* <div className="relative">
              <BellIcon title="notification" width="1.3rem" />
              <span className="absolute flex h-3 w-3 -top-0.5 -right-0.5">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#F2EC2C] opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-[#F2EC2C]"></span>
              </span>
            </div> */}

            {matchesMdDown ? (
              currentPath.includes("operations") ||
              authCtx.isAuthenticated === true ? (
                // Mobile Mode
                <Stack direction="row" alignItems="center" spacing={2}>
                  <StyledBadge
                    color="warning"
                    className="opacity-75"
                    // badgeContent={3}
                    max={99}
                  >
                    <BellIcon
                      // onClick={() =>
                      //   history.push("/operations/notifications")
                      // }
                      title="notifications"
                      width="1rem"
                      className="cursor-pointer"
                    />
                  </StyledBadge>
                  <MHMenu items={MENU_ITEMS} onMenuToggle={() => {}}>
                    <Avatar
                      alt={user?.firstName}
                      src={user?.firstName}
                      // variant="rounded"
                      sx={{}}
                    />
                  </MHMenu>
                </Stack>
              ) : currentPath.includes("auth") ? null : (
                <Stack direction="row" alignItems="end" spacing={2}>
                  {/* <MHMenu items={LG_ITEMS} onMenuToggle={(open) => {}}>
                    <Avatar
                      alt={user?.firstName}
                      src={user?.firstName}
                      // variant="rounded"
                      sx={{}}
                    />
                  </MHMenu> */}

                  <MHButton
                    // type="submit"
                    form="login-form"
                    onClick={() => history.push("/auth/sign-in")}
                    sx={{
                      height: 40,
                      width: 100,
                      borderRadius: "4px",
                    }}
                  >
                    Login
                  </MHButton>
                </Stack>
              )
            ) : // WebPage Mode
            currentPath.includes("operations") ||
              authCtx.isAuthenticated === true ? (
              <Stack direction="row" alignItems="center" spacing={4}>
                <StyledBadge
                  color="warning"
                  className="opacity-75"
                  // badgeContent={3}
                  max={99}
                >
                  <BellIcon
                    // onClick={() => history.push("/operations/notifications")}
                    title="notifications"
                    width="1rem"
                    className="cursor-pointer"
                  />
                </StyledBadge>
                <MHMenu items={MENU_ITEMS} onMenuToggle={(open) => {}}>
                  <Box
                    id="profile-button"
                    aria-controls={"profile-menu"}
                    aria-haspopup="true"
                  >
                    <Stack direction="row" alignItems="center" spacing={1.25}>
                      <Avatar
                        alt={user?.firstName}
                        src={user?.firstName}
                        // variant="rounded"
                        sx={{}}
                      />
                      <Typography variant="body2" color="primary">
                        {user?.firstName}&nbsp;
                        {user?.lastName}
                      </Typography>

                      <CaretDownIcon width=".85rem" height=".85rem" />
                    </Stack>
                  </Box>
                </MHMenu>
              </Stack>
            ) : currentPath.includes("auth") ? null : (
              <Stack direction="row" alignItems="center" spacing={4}>
                <ul
                  style={{
                    listStyleType: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    padding: 0,
                  }}
                >
                  <li style={{ margin: "0 20px" }}>
                    <a
                      href="/auth/sign-in"
                      className="removeUnderline cursor-pointer block"
                    >
                      <Typography
                        variant="body1"
                        fontSize={{ xs: "13px", sm: "13px" }}
                        letterSpacing={{ xs: "-4.5%", sm: "-4.5%" }}
                        lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                        color="primary"
                      >
                        Login
                      </Typography>
                    </a>
                  </li>
                  <li style={{ margin: "0 20px" }}>
                    <a
                      href="/auth/onboard"
                      className="removeUnderline cursor-pointer block ring-black-50 ring-1 w-[95px] px-2 py-2 rounded"
                    >
                      <Typography
                        variant="body1"
                        fontSize={{ xs: "13px", sm: "13px" }}
                        letterSpacing={{ xs: "-4.5%", sm: "-4.5%" }}
                        lineHeight={{ xs: "160.5%", sm: "160.5%" }}
                        color="primary"
                      >
                        Get Started
                      </Typography>
                    </a>
                  </li>
                </ul>
              </Stack>
            )}

            {/* <IconButtonUnstyled
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}>
              <Stack direction="row" alignItems="center" spacing={1.25}>
                <Avatar
                  alt="Avatar"
                  src="https://res.cloudinary.com/mother-honestly/image/upload/v1657976885/christina-wocintechchat-com-0Zx1bDv5BNY-unsplash_dxhylh.png"
                  variant="rounded"
                  sx={{}}
                />
                <Typography color="primary">
                  {user?.firstName}&nbsp;
                  {user?.lastName}
                </Typography>

                <CaretDownIcon />
              </Stack>
            </IconButtonUnstyled> */}

            <Menu
              id="app-bar-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </Stack>
        </Toolbar>
      </AppBarStyled>
    </Box>
  );
};

export default AppBar;
