import { ReactComponent as GridIcon } from "../static/svg/grid.svg";
import { ReactComponent as UsersIcon } from "../static/svg/users.svg";
import { ReactComponent as PersonsIcon } from "../static/svg/persons.svg";
import { ReactComponent as ContractIcon } from "../static/svg/letter.svg";
import { ReactComponent as ScreeningIcon } from "../static/svg/screen.svg";
import { ReactComponent as TariningIcon } from "../static/svg/training.svg";

export const menuItems = [
  {
    text: "Dashboard",
    icon: <GridIcon />,
    path: "/operations/dashboard",
  },
];

export const userItems = [
  {
    text: "Employers",
    icon: <UsersIcon />,
    path: "/operations/employers",
  },

  {
    text: "Households",
    icon: <PersonsIcon />,
    path: "/operations/households",
  },

  // {
  //   text: "Care Centers",
  //   icon: <PersonsIcon />,
  //   path: "/operations/care-centers",
  // },
];

export const detailsItems = [
  {
    text: "Contract",
    icon: <ContractIcon />,
    path: "/operations/contracts",
  },

  {
    text: "Screening / Checks",
    icon: <ScreeningIcon />,
    path: "/operations/screenings",
  },

  {
    text: "Trainings",
    icon: <TariningIcon />,
    path: "/operations/trainings",
  },
];
