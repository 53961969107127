// import { ReactComponent as MHIcon } from "../../static/svg/brand/MHWorkLife_Stacked_Navy.svg";
import { ReactComponent as CBIcon } from "../../static/svg/brand/caring-blocks-hrzns.svg";
import { ReactComponent as CBStackedIcon } from "../../static/svg/brand/careBlocks.svg";

export default function MHLogo({ style }: { style?: object }) {
  return (
    <CBIcon
      style={{
        margin: "0",
        ...style,
      }}
      className="h-fit"
    />
  );
}
export function MHStackedLogo({ style }: { style?: object }) {
  return (
    <CBStackedIcon
      style={{
        margin: "0",
        ...style,
      }}
      className="h-fit"
    />
  );
}
