import React, { useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import MHButton from "../components/Common/Button/MHButton";

import { ReactComponent as CalendarIcon } from "../static/svg/calendar.svg";
import { ReactComponent as MailIcon } from "../static/svg/envelope.svg";

import RoundedLogoIcon from "../theme/icons/RoundedLogo";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import SnackbarContext from "../store/context/snackbar.context";

import "../../src/CustomEditor.scss";
// import ContractHeader from "../Contract/ContractItems/ContractHeader";
// import ContractCard, {
//   ContractCardProps,
// } from "../Contract/ContractItems/ContractCard";
import useHttp from "../hooks/use-http";
import { ContractProps } from "../models/contract.model";
import { getURLWithQueryParams } from "../utils/utils";
import { HttpResponse } from "../models/api.interface";
import { useReactToPrint } from "react-to-print";
import ReactQuill from "react-quill";
import ContractHeader from "./ContractItems/ContractHeader";
import MyContractCard, {
  ContractCardProps,
} from "./ContractItems/MyContractCard";

type Props = {};

interface RouteParams {
  customerId?: string;
}

const ContractPreview = (props: Props) => {
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { customerId } = useParams<RouteParams>();
  const { loading, error, sendHttpRequest } = useHttp();

  const [contractDetails, setContractDetails] =
    React.useState<ContractProps | null>(null);
  const [contractData, setContractData] = React.useState("");

  // console.log("customerId", customerId);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employer/contract",
        {
          customerId: String(customerId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<ContractProps>) => {
        if (response.status === 200) {
          console.log(response.data);
          setContractDetails(response.data);
        } else {
          console.error("Failed to fetch contract details:", response);
        }
      }
    ).catch((err) => {
      console.error("Error fetching contract details:", err);
    });
  }, [customerId]);

  // console.log("contractDetails", contractDetails);

  React.useEffect(() => {
    if (contractDetails?.content) {
      setContractData(contractDetails?.content || "");
    }
  }, [contractDetails]);

  // const employer = `${organization?.firstName} ${organization?.lastName}`; // Format employer as a string

  // const currentDate = dayjs().format("MMMM DD, YYYY");

  // console.log(currentDate);
  //   console.log("organization", organization);

  function copyPageLinkToClipboard() {
    const newWhatsappNo = contractDetails?.whatsAppNo?.replace("+", "");

    // console.log("newWhatsappNo", newWhatsappNo);

    const currentPageLink = `https://caringblocks.com/auth/employee/create-password?telephone=${newWhatsappNo}`;
    navigator.clipboard
      .writeText(currentPageLink)
      .then(() => {
        // console.log("Page link copied to clipboard:", currentPageLink);
        // You can provide feedback to the user that the link has been copied successfully
        toast({
          message: `Employee contract link copied to clipboard`,
          variant: "success",
        });
      })
      .catch((error) => {
        // console.error("Error copying page link to clipboard:", error);
        // Handle any errors that may occur during copying
        toast({
          message: `Error copying page link to clipboard`,
          variant: "error",
        });
      });
  }

  const STATS: ContractCardProps[] = [
    {
      theme: "default",
      icon: (
        <RoundedLogoIcon
          sx={{
            p: 0.6,
            mb: 2,
            backgroundColor: "#C7D8E4",
          }}
        >
          <CalendarIcon className="w-6 h-6" />
        </RoundedLogoIcon>
      ),
      title: "Start Date",
      stat: dayjs(contractDetails?.createdDate).format("MMMM D, YYYY"),
      pattern: false,
      divide: true,
    },
    {
      theme: "default",
      icon: (
        <Avatar
          className="w-16 h-16"
          src=""
          alt={contractDetails?.employerName}
        />
      ),
      name: contractDetails?.employerName,
      employerEmail: "contract created by",
      pattern: true,
      // buttonLabel: "Invite Employee",
      disableBtn: true,
      // onClick: handleInviteOpen,
    },
  ];
  const steps = ["Contract Created", "Invitation Sent", "Employee Signature"];

  const componentRef = useRef(null);

  const handleDownloadClick = useReactToPrint({
    content: () => componentRef.current,
  });

  //   const uniqueReference = generateUniqueReference();
  //   console.log("uniqueReference", uniqueReference);

  // const locations = [
  //   // {
  //   //   label: "Preview",
  //   //   link: `/organization/contracts/preview/${contractForm.contractRef}`,
  //   // },
  //   // {
  //   //   label: "Cancel Contract",
  //   //   link: "",
  //   //   // path: openContractModal,
  //   // },
  //   // {
  //   //   label: "Edit Template",
  //   //   link: `/organization/contract/edit/${contractForm.contractRef}`,
  //   // },
  // ];

  return (
    <React.Fragment>
      <ContractHeader
        label={`Contract For:`}
        label2={`${contractDetails?.employeeName}`}
        // buttonLabel="New Contract"
        // handleContract={openContract}
      />

      <Grid
        container
        spacing={0}
        className=" place-content-start md:justify-between gap-2 md:gap-0 bg-[#F9F9F7]"
      >
        {STATS.map((stat, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={index === 0 ? 5 : 7} // Set md value based on index
            key={stat.title}
            p={4}
            // onClick={handleOpen}
            sx={{
              // cursor: "pointer",
              backgroundColor: "#F9F9F7",
              borderRadius: 3,
            }}
            className=" order-1 lg:order-2"
          >
            <Box className=" " position={"relative"}>
              <MyContractCard {...stat} />
            </Box>
          </Grid>
        ))}

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          // px={4}
          // py={0}
          sx={{
            // cursor: "pointer",
            backgroundColor: "#F9F9F7",
            borderRadius: 3,
          }}
          // className="order-3 mx-auto px-0 md:px-4 py-0"
          className="order-3 mx-auto px-8 md:px-4 py-0"
        >
          <Box
            px={{ xs: 4, sm: 8 }}
            py={4}
            bgcolor={"common.white"}
            borderRadius={2}
            boxShadow="0px 5px 26px #C7D8E4"
            minHeight={100}
            sx={{
              gap: 2,
              pt: 5,
              mb: 3,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box className=" lg:flex justify-evenly items-center space-x-6">
              <Box className="order-1 lg:order-none py-2 flex justify-center space-x-6">
                <Avatar
                  sx={{ bgcolor: "#28404A" }}
                  className="w-12 h-12 md:w-16 md:h-16"
                  src={contractDetails?.email}
                  alt={"M"}
                >
                  <MailIcon className="w-5 h-5 md:w-8 md:h-8" />
                </Avatar>
                <Box>
                  <Typography
                    //   justifyItems={"center"}
                    variant="subtitle1"
                    fontSize={{ xs: "15px", sm: "18px", md: "20px" }}
                    color={"primary.main"}
                    //   gutterBottom
                    className={`pt-2`}
                  >
                    {contractDetails?.status === "CREATED"
                      ? "Awaiting Employee Signature"
                      : contractDetails?.status === "COMPLETED"
                      ? "Contract Completed"
                      : "Invitation Pending"}
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                    color={"primary.main"}
                    gutterBottom
                    className={`opacity-60 rounded-md bg-[#EEEEEE] text-[#A9A9A9] px-3 py-0 w-fit`}
                  >
                    Household Staff
                  </Typography>
                </Box>
              </Box>

              <Box className="order-2 lg:order-none lg:float-right block md:flex justify-center  lg:block lg:space-x-0">
                <Box className="w-full centerItems">
                  <Typography
                    //   justifyItems={"center"}
                    variant="subtitle1"
                    fontSize={{ xs: "10px", sm: "18px", md: "14px" }}
                    color={"primary.main"}
                    //   gutterBottom
                    className={`capitalize flex items-center justify-between gap-6`}
                  >
                    Email:
                    <p className=" lowercase font-areaSemi">
                      {contractDetails?.email}
                    </p>
                  </Typography>
                </Box>
                <Box className="w-full centerItems">
                  <Typography
                    //   justifyItems={"center"}
                    variant="subtitle1"
                    fontSize={{ xs: "10px", sm: "18px", md: "14px" }}
                    color={"primary.main"}
                    //   gutterBottom
                    className={`capitalize flex items-center justify-between gap-6`}
                  >
                    Whatsapp:
                    <p className=" lowercase font-areaSemi">
                      {contractDetails?.whatsAppNo}
                    </p>
                  </Typography>
                </Box>
              </Box>

              <Box className="order-3 lg:order-none lg:float-right pt-4 flex justify-center space-x-6 lg:block lg:space-x-0">
                <Box className=" items-center">
                  <MHButton
                    //   type="submit"
                    form="add-employee-form"
                    //   loading={loading}
                    className="rounded-[4px] cursor-pointer "
                    onClick={copyPageLinkToClipboard}
                    sx={{
                      width: { xs: 180, sm: 180, md: 180 },
                      // bgcolor: "#E0CBCB",
                      // color: "#C27171",
                      // "&:hover": {
                      //   // Define hover styles here
                      //   bgcolor: "#E0CBCB",
                      //   color: "#C27171",
                      // },
                    }}
                  >
                    Copy Invitation
                  </MHButton>

                  <Box className="centerItems">
                    <Button
                      onClick={copyPageLinkToClipboard}
                      className="no-underline cursor-pointer -ml-2"
                    >
                      <Typography
                        variant="body1"
                        fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                        color={"#0060F0"}
                        gutterBottom
                        className={`capitalize py-1`}
                      >
                        Copy invitation link
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={8.5}
          // p={4}
          p={{ xs: 1, sm: 4 }}
          sx={{
            // cursor: "pointer",
            backgroundColor: "#F9F9F7",
            borderRadius: 3,
          }}
          className="order-4"
        >
          <Box
            // p={3}
            bgcolor={"common.white"}
            borderRadius={2}
            boxShadow="0px 5px 26px #C7D8E4"
            minHeight={180}
            sx={{
              gap: 2,
              pt: 5,
              mb: 3,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div
              id="contractPreviewEmployer"
              ref={componentRef}
              style={{ padding: "10px" }}
            >
              {/* <Typography
              variant="body1"
              fontSize={{ xs: "10px", sm: "18px", md: "18px" }}
              color={"primary.main"}
              gutterBottom
              className={`capitalize`}
            >
              {editorHtml}
            </Typography> */}

              {/* <Box className="relative quillEditor">
                <div
                  className=""
                  dangerouslySetInnerHTML={{ __html: contractData }}
                />
              </Box> */}

              <Box className="relative quillEditor">
                <ReactQuill
                  value={contractData}
                  readOnly={true}
                  theme={"bubble"}
                />
              </Box>

              <Box
                mt={4}
                mb={0}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <Box
                  className="space-y-4 justify-center"
                  sx={{ width: { xs: "128px", sm: "192px" } }}
                >
                  <Box>
                    {contractDetails?.employerImgUrl && (
                      <>
                        <img
                          src={contractDetails?.employerImgUrl}
                          alt="signature"
                          className="signature"
                        />
                      </>
                    )}
                    <hr className="md:w-48 w-24" />
                  </Box>
                  <Typography
                    variant="body1"
                    align="center"
                    fontSize={{ xs: "12px", sm: "16px", md: "16px" }}
                    className=" capitalize"
                  >
                    {contractDetails?.employerName}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                    className=" capitalize"
                  >
                    Date:{" "}
                    {dayjs(contractDetails?.createdDate).format(
                      "MMMM DD, YYYY"
                    )}
                  </Typography>
                </Box>
                <Box
                  className="space-y-4 justify-center"
                  sx={{ width: { xs: "128px", sm: "192px" } }}
                >
                  <Box>
                    {contractDetails?.employeeImgUrl && (
                      <>
                        <img
                          src={contractDetails?.employeeImgUrl}
                          alt="signature"
                          className="signature"
                        />
                      </>
                    )}
                    <hr className="w-48" />
                  </Box>
                  <Typography
                    variant="body1"
                    align="center"
                    fontSize={{ xs: "12px", sm: "16px", md: "16px" }}
                    className=" capitalize"
                  >
                    {contractDetails?.employeeName}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                    className=" capitalize"
                  >
                    Date:{" "}
                    {dayjs(contractDetails?.updatedDate).format(
                      "MMMM DD, YYYY"
                    )}
                  </Typography>
                </Box>
              </Box>
            </div>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={3.5}
          p={4}
          sx={{
            // cursor: "pointer",
            backgroundColor: "#F9F9F7",
            borderRadius: 3,
          }}
          className="order-5"
        >
          <Box
            p={3}
            bgcolor={"common.white"}
            borderRadius={2}
            boxShadow="0px 5px 26px #C7D8E4"
            minHeight={180}
            sx={{
              gap: 2,
              pt: 5,
              mb: 3,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Typography
              variant="subtitle1"
              fontSize={{ xs: "16px", sm: "18px", md: "18px" }}
              color={"primary.main"}
              gutterBottom
              className={`capitalize ml-6`}
            >
              Contractor Timeline
            </Typography>

            <Stepper
              className="ml-6 py-5"
              activeStep={contractDetails?.employeeImgUrl === "" ? 2 : 3}
              orientation="vertical"
            >
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel>
                    <Typography
                      variant="subtitle1"
                      fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                      color={"primary.main"}
                      gutterBottom
                      className={`capitalize`}
                    >
                      {step}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <Box className=" text-center">
              {contractDetails?.contractRef !== "" ? (
                <MHButton
                  //   type="submit"
                  form="add-employee-form"
                  //   loading={loading}
                  className="rounded-[4px] cursor-pointer "
                  onClick={handleDownloadClick}
                  sx={{
                    width: { xs: 180, sm: 180, md: 180 },
                  }}
                >
                  Download PDF
                </MHButton>
              ) : null}

              {/* <Box className="text-start ml-14 py-8">
                {locations.map((item, index) =>
                  item.link.includes("/organization") ? (
                    contractForm.contractRef !== "" ? (
                      contractForm.contractInvite === "pending" ? null : (
                        <Link
                          to={item.link}
                          key={index}
                          className="no-underline cursor-pointer"
                        >
                          <Typography
                            variant="subtitle1"
                            fontSize={{ xs: "12px", sm: "12px", md: "13px" }}
                            color={"primary.main"}
                            gutterBottom
                            className={`capitalize py-1`}
                          >
                            {item.label}
                          </Typography>
                        </Link>
                      )
                    ) : null
                  ) : contractForm.contractRef !== "" ? (
                    contractForm.contractInvite === "pending" ? null : (
                      <Button
                        // onClick={item.path}
                        className="no-underline cursor-pointer -ml-2"
                      >
                        <Typography
                          variant="subtitle1"
                          fontSize={{ xs: "12px", sm: "12px", md: "13px" }}
                          color={"primary.main"}
                          gutterBottom
                          className={`capitalize py-1`}
                        >
                          {item.label}
                        </Typography>
                      </Button>
                    )
                  ) : null
                )}
              </Box> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ContractPreview;
