import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import TextCard from "../components/Feature/General/TextCard";

import { ReactComponent as GroupIcon } from "../static/svg/employers-group.svg";
import { ReactComponent as EmployerIcon } from "../static/svg/employer.svg";
import { ReactComponent as MaleIcon } from "../static/svg/male.svg";
import { ReactComponent as FemaleIcon } from "../static/svg/female.svg";
import { ReactComponent as SearchIcon } from "../static/svg/search-white.svg";
import { ReactComponent as GridIcon } from "../static/svg/grid-box.svg";
import { ReactComponent as ListIcon } from "../static/svg/list.svg";

import * as validators from "../utils/validators";

import useInput from "../hooks/use-input";
import MHButton from "../components/Common/Button/MHButton";
import PhotoCard from "../components/Feature/General/PhotoCard";
import useHttp from "../hooks/use-http";
import { getURLWithQueryParams } from "../utils/utils";
import { HttpResponse } from "../models/api.interface";
import dayjs from "dayjs";
import { MHPagination } from "../components/Common/UI/MHPagination";
import MHTextInput from "../components/Common/Form/MHTextInput";
import { EmployeeData } from "../models/employee.model";
import DataLoader from "../components/Common/Loading/DataLoader";
import { formatDateWithoutDay, getStartAndEndDates } from "../utils/getdate";

type Props = {};

const Employees = (props: Props) => {
  const {
    value: enteredFirstName,
    valid: enteredFirstNameIsValid,
    onChange: firstNameInputChangeHandler,
    onBlur: firstNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredLastName,
    valid: enteredLastNameIsValid,
    onChange: lastNameInputChangeHandler,
    onBlur: lastNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredRefId,
    valid: enteRedrefIdIsValid,
    onChange: refIdInputChangeHandler,
    onBlur: refIdInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const [count, setCount] = React.useState(0);
  const [employees, setEmployees] = React.useState<EmployeeData[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const { sendHttpRequest } = useHttp();

  const fetchEmployees = (offset: number) => {
    // console.log(`Fetching employees with offset: ${offset}`);
    setIsLoading(true);
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL +
          `/employee/all?offset=${offset}&_=${new Date().getTime()}`, // Adding a timestamp to avoid caching
        {
          employerRefId: String("ABC"),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<EmployeeData[]>) => {
        // console.log("API response:", response.data);
        setEmployees(response.data);
        setIsLoading(false);
      }
    );
  };

  React.useEffect(() => {
    fetchEmployees(count);
  }, [count]);

  const handlePrev = () => {
    setCount((prevCount) => Math.max(prevCount - 20, 0));
  };

  const handleNext = () => {
    setCount((prevCount) => prevCount + 20);
  };

  const [filteredEmployees, setFilteredEmployees] = React.useState<
    EmployeeData[]
  >([]);
  React.useEffect(() => {
    let filtered = employees;

    if (enteredFirstName) {
      filtered = filtered.filter((employee) =>
        employee?.firstName
          ?.toLowerCase()
          .includes(enteredFirstName.toLowerCase())
      );
    }

    if (enteredLastName) {
      filtered = filtered.filter((employee) =>
        employee?.lastName
          ?.toLowerCase()
          .includes(enteredLastName.toLowerCase())
      );
    }

    if (enteredRefId) {
      filtered = filtered.filter((employee) =>
        employee?.employerRefId?.toString().includes(enteredRefId)
      );
    }

    setCurrentPage(1);
    setFilteredEmployees(filtered);
  }, [enteredFirstName, enteredLastName, enteredRefId, employees]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const postsPerPage = 10;
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredEmployees.slice(firstPostIndex, lastPostIndex);

  const noOfPage = Math.ceil((filteredEmployees?.length || 0) / postsPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const maleEmployees = employees.filter((employee) => employee.sex === "Male");
  const femaleEmployees = employees.filter(
    (employee) => employee.sex === "Female"
  );

  // Filter employees based on current month
  const newEmployees = employees.filter((employee) => {
    const createdDate = new Date(employee.createdDate);
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    return (
      createdDate.getMonth() === currentMonth &&
      createdDate.getFullYear() === currentYear
    );
  });

  // Get start and end dates
  const { start, end } = getStartAndEndDates();

  if (isLoading && employees.length === 0) {
    return <DataLoader open />;
  }

  // console.log("count", count);
  // console.log("employees", employees);
  // console.log("filteredEmployees", filteredEmployees);

  return (
    <React.Fragment>
      <Box
        sx={{
          //   color: "#194049",
          paddingX: "20px",
        }}
      >
        <Typography
          variant="subtitle1"
          fontSize={"23px"}
          paddingTop={5}
          paddingBottom={3}
        >
          Employees
        </Typography>

        <Box className="grid lg:flex lg:flex-row lg:gap-6">
          <Box
            // sx={{ display: { sm: "flex" }, gap: { xs: 3, md: 8 } }}
            className="  lg:basis-[80%] "
          >
            <Box
              sx={{
                display: { xs: "grid", md: "flex" },
                gap: 2,
                alignItems: "center",
                justifyContent: "center",
              }}
              className=" grid-cols-2 md:grid-none"
            >
              <TextCard
                title="Total Employees"
                count={employees.length}
                color="#fff"
                autoHeight={false}
                icon={<GroupIcon className="w-8 h-8" />}
              />
              <TextCard
                title="New Employees"
                count={newEmployees.length}
                color="#fff"
                autoHeight={false}
                icon={<EmployerIcon className="w-8 h-8" />}
                showState={true}
                startDate={formatDateWithoutDay(start)}
                endDate={formatDateWithoutDay(end)}
              />
              <TextCard
                title="Male"
                count={maleEmployees.length}
                color="#fff"
                autoHeight={false}
                icon={<MaleIcon className="w-8 h-8" />}
              />
              <TextCard
                title="Female"
                count={femaleEmployees.length}
                color="#fff"
                autoHeight={false}
                icon={<FemaleIcon className="w-8 h-8" />}
              />
            </Box>

            <Box
              sx={{
                border: "1px solid #e8e8e8",
                boxShadow: "0px 0px 3px 2px #E8E8E8",
                // zIndex: 10,
                borderRadius: "8px",
                marginTop: 5,
                // height: "100%",
                // justifyContent: "center",
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={1}
                  md={1}
                  mt={0}
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="hidden lg:flex"
                >
                  {React.cloneElement(<GridIcon className="w-6 h-6" />, {
                    style: {
                      fill: "#194049",
                    },
                  })}

                  {React.cloneElement(<ListIcon className="w-6 h-6" />, {
                    style: {
                      fill: "#194049",
                    },
                  })}
                </Grid>
                <Grid item xs={12} sm={3.8} md={3} mt={2}>
                  <MHTextInput
                    id="employeeFname"
                    type="text"
                    placeholder="Employee First Name"
                    value={enteredFirstName}
                    onChange={firstNameInputChangeHandler}
                    onBlur={firstNameInputBlurHandler}
                    required
                    // autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={3.8} md={3} mt={2}>
                  <MHTextInput
                    id="employeeLname"
                    type="text"
                    placeholder="Employee Last Name"
                    value={enteredLastName}
                    onChange={lastNameInputChangeHandler}
                    onBlur={lastNameInputBlurHandler}
                    required
                    // autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={3.8} md={3} mt={2}>
                  <MHTextInput
                    id="employeeId"
                    type="text"
                    placeholder="Employee Reference ID"
                    value={enteredRefId}
                    onChange={refIdInputChangeHandler}
                    onBlur={refIdInputBlurHandler}
                    required
                    // autoFocus
                  />
                </Grid>
                {/* <Grid item xs={12} sm={1}>
                  <MHButton
                    sx={{
                      height: "44px",
                      width: "60px",
                      marginTop: "-3px",
                      borderRadius: "4px",
                    }}
                    type="submit"
                    // loading={loading}
                    // fullWidth
                  >
                    <SearchIcon className="w-8 h-8" />
                  </MHButton>
                </Grid> */}
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 2,
                }}
              >
                {currentPosts &&
                  currentPosts.map((employer) => (
                    <Grid item xs={6} sm={5} md={2.3} key={employer.id}>
                      <PhotoCard
                        name={`${employer.firstName} ${employer.lastName}`}
                        phone={`${employer.whatsAppNumber}`}
                        // imgSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1663241743/BlessingAdesiyan_eixt3a.png"
                        details={[
                          {
                            label: "Employer Ref Id",
                            text: employer.employerRefId,
                          },
                          {
                            label: "Joined on",
                            text: dayjs(employer.createdDate).format(
                              "MMMM D, YYYY"
                            ),
                          },
                        ]}
                        msgPath="/operations/employees"
                        tranxPath={`/operations/transactions/${employer.customerId}`}
                      />
                    </Grid>
                  ))}
              </Grid>

              <Box display={"flex"} justifyContent={"center"} my={2}>
                <MHPagination
                  count={noOfPage}
                  variant="outlined"
                  shape="rounded"
                  page={currentPage}
                  onChange={handlePageChange}
                  color={"primary"}
                />
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                marginTop={2}
                mx={1}
                mb={2}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handlePrev}
                  disabled={isLoading} // Disable button when loading
                >
                  {isLoading ? "Loading..." : "Previous 20 Records"}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={isLoading} // Disable button when loading
                >
                  {isLoading ? "Loading..." : "Next 20 Records"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Employees;
