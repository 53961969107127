import { Box, Typography } from "@mui/material";
import React from "react";
import MHButton from "../../components/Common/Button/MHButton";

// import { ReactComponent as AddIcon } from "../../../static/svg/plus-lg.svg";

type Props = {
  label?: string;
  label2?: string;
  buttonLabel?: string;
  handleContract?: () => void;
};

const ContractHeader = (props: Props) => {
  return (
    <Box className="flex place-content-between items-center">
      <Box className="py-16 px-8 text-start flex items-center gap-4">
        <Typography
          variant="h1"
          align="left"
          fontSize={{ xs: "26px", sm: "26px", md: "38px" }}
        >
          {props.label}
        </Typography>
        <Typography
          variant="h1"
          align="left"
          fontSize={{ xs: "26px", sm: "26px", md: "38px" }}
          className=" opacity-60"
        >
          {props.label2}
        </Typography>
      </Box>
      {props.buttonLabel && (
        <MHButton
          // startIcon={<AddIcon className="mr-1" width=".8rem" />}
          sx={{
            width: 280,
            height: 52,
          }}
          className="rounded-[4px] uppercase text-xs"
          onClick={props.handleContract}
        >
          {props.buttonLabel}
        </MHButton>
      )}
    </Box>
  );
};

export default ContractHeader;
