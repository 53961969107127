// PaymeDialog.tsx
import React, { useState } from "react";
import MHDialog from "../../Common/Dialog/MHDialog";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import MHButton from "../../Common/Button/MHButton";
import RoundedLogoIcon from "../../../theme/icons/RoundedLogo";

import MHFormControl from "../../Common/Form/MHFormControl";
import InputAdornment from "../../Common/Form/InputAdornment";
import * as validators from "../../../utils/validators";

import useInput from "../../../hooks/use-input";
import SnackbarContext from "../../../store/context/snackbar.context";

import { ReactComponent as WalletIcon } from "../../../static/svg/wallet.svg";
import MHAutocomplete, { Option } from "../../Common/Form/MHAutocomplete";
import { BillingType, RACE_OPTIONS } from "../../../utils/constants";
import Label from "../../Common/Form/Label";

interface PaymeDialogProps {
  open: boolean;
  onClose: () => void;
  empName?: string | undefined;
  employerRefId?: number | any;
  customerId?: number | any;
}

const PaymeDialog: React.FC<PaymeDialogProps> = ({
  open,
  onClose,
  empName,
  employerRefId,
  customerId,
}) => {
  const {
    value: enteredAmount,
    valid: enteredAmountIsValid,
    error: amountInputHasError,
    onChange: amountInputChangeHandler,
    onBlur: amountInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredPaymentReason,
    valid: enteredPaymentReasonIsValid,
    error: paymentReasonInputHasError,
    onChange: paymentReasonInputChangeHandler,
    onBlur: paymentReasonInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredBillingType,
    valid: enteredBillingTypeIsValid,
    error: billingTypeInputHasError,
    onChange: billingTypeInputChangeHandler,
    onBlur: billingTypeInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const billingTypeOptions: Option[] = BillingType.map((role) => ({
    label: role.label as string,
    value: role.value,
  }));

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const filteredNumber = enteredAmount?.replace(/,/g, "");

  const [toggleState, setToggleState] = useState(false);

  const formIsValid =
    enteredAmountIsValid &&
    enteredPaymentReasonIsValid &&
    enteredBillingTypeIsValid;

  const reqBody = {
    customerId: customerId,
    employerRefId: employerRefId,
    type: enteredBillingType,
    remarks: enteredPaymentReason,
    amount: Number(filteredNumber),
  };

  const submitHandler = async () => {
    if (!formIsValid) {
      toast({
        variant: "warning",
        message: "All fields are required",
      });
      return;
    }

    setToggleState(true);

    console.log("reqBody", reqBody);

    const url = process.env.REACT_APP_API_BASE_URL + `/tranx/billing`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        // Parse the response body as JSON
        const resData = await response.json();
        // console.log("resData", resData); // Correctly accessing the 'data' property
        toast({
          variant: "success",
          message: "Employer billed successfully.",
        });
        setToggleState(false);

        onClose();
      } else {
        const resData = await response.json();
        // console.log("resError", resData); // Correctly accessing the 'data' property
        toast({
          message: "Employer billing failed.",
          variant: "error",
        });
        setToggleState(false);
        onClose();
      }
    } catch (error) {
      // console.error("An error occurred:", error);
      toast({
        message: `An error occurred: ${error}`,
        variant: "error",
      });
      onClose();
      return null;
    }
  };

  return (
    <MHDialog
      open={open}
      title={` `}
      handleClose={onClose}
      maxWidth="md"
      sx={{ zIndex: 10 }}
      // actions={}
      scroll="paper"
    >
      <Box
        mb={0.6}
        height={{ xs: "full", sm: "500px" }}
        width={{ xs: "full", sm: "400px" }}
        className=" ml-0 mr-0 place-content-center items-center mx-auto "
      >
        <Box
          display="flex"
          alignItems="center"
          // alignSelf="center"
          // alignContent="center"
          justifyContent="center"
          mt={1}
        >
          <RoundedLogoIcon sx={{ backgroundColor: "#C7D8E4" }}>
            <WalletIcon className="w-6 h-6" />
          </RoundedLogoIcon>
          <Typography
            variant="h1"
            fontSize={{ xs: "18px", sm: "20px" }}
            align="center"
            gutterBottom
            display={"flex"}
            alignSelf={"center"}
            ml={2}
          >
            Billing
          </Typography>
        </Box>

        {toggleState === false && (
          <Box>
            <Box display="flex" justifyContent="center" mt={4}>
              <Box width="294px">
                <Typography
                  variant="subtitle2"
                  fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
                  gutterBottom
                  align="left"
                  justifyContent="start"
                  pl={1}
                >
                  Employer Name
                </Typography>

                <Button
                  // onClick={copyBankToClipboard}
                  disabled
                  className="removeUnderline"
                  fullWidth
                  disableRipple
                >
                  <Box
                    className=""
                    width={{ xs: "100%", sm: "100%" }}
                    height={{ xs: "45px", sm: "45px" }}
                    sx={{
                      border: 1,
                      borderColor: "#D9D9D9",
                      borderRadius: 0.5,
                      px: 1,
                    }}
                    alignItems="center"
                    display="flex"
                    // gap={2}
                  >
                    <Typography
                      variant="subtitle1"
                      fontSize={{ xs: "10px", sm: "13px", md: "13px" }}
                      color={"primary"}
                      gutterBottom
                      align="left"
                      justifyContent="start"
                      width={{ xs: "100%", sm: "100%" }}
                      textTransform="capitalize"
                      my={3}
                    >
                      {empName}
                    </Typography>
                  </Box>
                </Button>
              </Box>
            </Box>

            <Box display="flex" justifyContent="center">
              <Box width="280px">
                <MHFormControl
                  id="amount"
                  type="number"
                  label="Enter amount"
                  placeholder=""
                  value={enteredAmount}
                  onChange={amountInputChangeHandler}
                  onBlur={amountInputBlurHandler}
                  startAdornment={
                    <InputAdornment className="!bg-[#D9D9D9] md:h-[43px]">
                      &#x20A6;
                    </InputAdornment>
                  }
                  required
                  // autoFocus
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="center">
              <Box width="280px">
                <MHAutocomplete
                  label="Billing Type"
                  placeholder="Select billing type"
                  options={billingTypeOptions}
                  onInputChange={(val) =>
                    billingTypeInputChangeHandler(val as string)
                  }
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" mt={1}>
              <Box width="280px">
                <MHFormControl
                  id="reason"
                  type="text"
                  label="Reason for billing"
                  placeholder="Why are you billing this employer?"
                  value={enteredPaymentReason}
                  onChange={paymentReasonInputChangeHandler}
                  onBlur={paymentReasonInputBlurHandler}
                  required
                  // autoFocus
                />
              </Box>
            </Box>

            <Box display="flex" justifyContent="center">
              <Box my={2} width={{ xs: "100%", sm: "280px" }}>
                <MHButton
                  color="primary"
                  sx={{}}
                  // type="submit"
                  className=" "
                  fullWidth
                  onClick={submitHandler}
                >
                  Submit Request
                </MHButton>
              </Box>
            </Box>
          </Box>
        )}

        {toggleState === true && (
          <>
            <Box className="" my={6} px={3}>
              <Box width="90%">
                <Typography
                  variant="body1"
                  fontStyle="italic"
                  color="primary"
                  fontSize={{ xs: 12, sm: 12, md: 12 }}
                >
                  Do not close payment window while we confirm payment
                </Typography>
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignContent={"center"}
                  alignItems={"center"}
                  my={10}
                >
                  <CircularProgress
                    size={100}
                    color="primary"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // my: 10,
                    }}
                  />
                </Box>

                <Label
                  style={{
                    fontFamily: "Area-Normal-Semibold",
                    fontSize: 13,
                    fontWeight: 400,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                  }}
                >
                  Please wait while we confirm your payment
                </Label>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </MHDialog>
  );
};

export default PaymeDialog;
