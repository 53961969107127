import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import UpdateOneTraining from "../components/Feature/Training/UpdateOneTraining";

type Props = {};

const UpdateTraining = (props: Props) => {
  return (
    <React.Fragment>
      <Box>
        <Typography
          variant="subtitle1"
          fontSize={"23px"}
          paddingTop={5}
          paddingBottom={3}
        >
          Update Training / Certificates
        </Typography>
        <Divider
          light
          variant="middle"
          sx={{
            borderColor: "#F3F4F6",
            my: 2,
          }}
        />
        <UpdateOneTraining />
      </Box>
    </React.Fragment>
  );
};

export default UpdateTraining;
