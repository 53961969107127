import { Avatar, Box, Divider, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as FolderIcon } from "../../../static/svg/folder.svg";

type TextProps = {
  label?: string | number | any;
  text?: string | number | any;
  imgSrc?: string | number | any;
};

type Props = {
  customerId?: number;
  contractName?: string;
  date?: string | any;
  employer?: string;
  employee?: string;
  since?: string | number | any;
  status?: boolean | any;
};

const PhotoFlexCard = (props: TextProps) => {
  return (
    <Box
      sx={{
        // display: "flex",
        // // justifyContent: "space-between",
        alignItems: "center",
        marginY: "3px",
        // gap: 3,
      }}
      className=" flex flex-row"
    >
      <Avatar
        // alt={props.name && props.name[0]}
        // src={props.imgSrc || (props.name && props.name[0])}
        alt={props.text && props.text[0]}
        src={props.imgSrc || (props.text && props.text[0])}
        sx={{
          width: 21,
          height: 21,
          border: "1px solid #E7E7E7",
          marginX: "auto",
          backgroundColor: "#194049",
          fontSize: "8px",
        }}
        className=" basis-[10%] "
      />
      <Typography
        variant="subtitle2"
        fontSize={"10px"}
        sx={{
          color: "#194049",
        }}
        className=" basis-[30%] pl-2 "
      >
        {props.label}
      </Typography>

      <Typography
        variant="subtitle2"
        fontSize={"10px"}
        sx={{
          color: "#A9A9A9",
        }}
        className=" basis-[60%] "
      >
        {props.text}
      </Typography>
    </Box>
  );
};

const ContractCard = (props: Props) => {
  return (
    <React.Fragment>
      <Box
        className="  rounded-md relative "
        height="215px"
        width="265px"
        paddingX={3}
        sx={{
          boxShadow: "0px 0px 5px 2px #E8E8E8",

          cursor: "pointer",
        }}
      >
        {props.status !== "COMPLETED" && (
          <Box
            sx={{
              background: "#F8D1CE",
              width: "52px",
              height: "17px",
              borderRadius: "2px",
              border: "1px  #FF0F00",
              color: "#FF0F00",
              position: "absolute",
              top: "10px",
              left: 100,
              paddingY: "3px",
              fontSize: "9px",
              textAlign: "center",
            }}
          >
            {props.since}
          </Box>
        )}
        <FolderIcon className="w-7 h-7 absolute top-5 left-5 " />

        <Box sx={{ width: "100%", paddingTop: 10 }}>
          <Typography
            variant="subtitle1"
            fontSize={"12px"}
            // paddingLeft={2.5}
            className=" line-clamp-1 "
          >
            {props.contractName}
          </Typography>

          <Divider sx={{ paddingTop: 2 }} />

          <Typography variant="body1" fontSize={"8px"} mt={2} sx={{}}>
            {props.date}
          </Typography>

          <PhotoFlexCard label="Employer:" text={props.employer} />
          <PhotoFlexCard label="Employee:" text={props.employee} />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ContractCard;
