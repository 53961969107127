import React from "react";
import { Switch, useRouteMatch, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

// import { styled } from "@mui/material/styles";

import Layout from "../components/Layout/Layout";
import AuthContext from "../store/context/auth-context";
import DashboardContext from "../store/context/dashboard.context";
// import Subscription from "../pages/Dashboard/Subscription";

import { AdminProtectedRoute } from "./ProtectedNavigator";
import AdminEditor from "../components/Common/Editor/AdminEditor";
import Startup from "../components/Startup/Startup";
import { SideTab } from "../components/Layout/SideTab";
import Dashboard from "../pages/Dashboard";
import { menuItems, userItems } from "../utils/data";
import Employers from "../pages/Employers";
import Employees from "../pages/Employees";
import Contract from "../pages/Contract";
import Training from "../pages/Training";
import Screening from "../pages/Screening";
import TransactionForEmployer from "../pages/TransactionsForEmployer";
import ContractPreview from "../pages/ContractPreview";
import UpdateTraining from "../pages/UpdateTraining";

// const Wrapper = styled("div")(
//   ({ theme }) => `
//     padding: ${theme.spacing(3)};
//   `
// );

const CaringBlocksNavigator = () => {
  const authCtx = React.useContext(AuthContext);
  const dashboardCtx = React.useContext(DashboardContext);

  const { organization } = dashboardCtx;

  const { path } = useRouteMatch();
  const location = useLocation();

  if (!authCtx.user || !organization) {
    return <Startup />;
  }

  // console.log("authCtx user", authCtx.user);

  // console.log("authCtx", authCtx);
  // console.log("operations", organization);
  // if (!authCtx.isOnboarded(authCtx.user as User)) {
  // return (
  //   <Redirect
  //     to={{
  //       pathname: '/onboarding/employer',
  //       state: { from: { pathname: '/dashboard' } }
  //     }}
  //   />
  // );
  // }

  return (
    <Layout>
      <title>Caring Blocks Employee Dashboard</title>

      <TransitionGroup>
        <CSSTransition
          unmountOnExit
          key={location.pathname}
          classNames="fade"
          timeout={400}
        >
          <Switch location={location}>
            {/* Admin Route Starts */}

            <AdminProtectedRoute
              path={`${path}/dashboard`}
              exact
              component={() => (
                <SideTab>
                  <Dashboard />
                </SideTab>
              )}
            />

            <AdminProtectedRoute
              path={`${path}/employers`}
              exact
              component={() => (
                <SideTab>
                  <Employers />
                </SideTab>
              )}
            />

            <AdminProtectedRoute
              path={`${path}/transactions/:customerId`}
              exact
              component={() => (
                <SideTab>
                  <TransactionForEmployer />
                </SideTab>
              )}
            />

            <AdminProtectedRoute
              path={`${path}/households`}
              exact
              component={() => (
                <SideTab>
                  <Employees />
                </SideTab>
              )}
            />

            <AdminProtectedRoute
              path={`${path}/contracts`}
              exact
              component={() => (
                <SideTab>
                  <Contract />
                </SideTab>
              )}
            />

            <AdminProtectedRoute
              path={`${path}/contracts/preview/:customerId`}
              exact
              component={() => (
                <SideTab>
                  <ContractPreview />
                </SideTab>
              )}
            />

            <AdminProtectedRoute
              path={`${path}/screenings`}
              exact
              component={() => (
                <SideTab>
                  <Screening />
                </SideTab>
              )}
            />

            <AdminProtectedRoute
              path={`${path}/trainings`}
              exact
              component={() => (
                <SideTab>
                  <Training />
                </SideTab>
              )}
            />

            <AdminProtectedRoute
              path={`${path}/trainings/update/:id`}
              exact
              component={() => (
                <SideTab>
                  <UpdateTraining />
                </SideTab>
              )}
            />

            <AdminProtectedRoute
              path={`${path}/editor`}
              exact
              component={AdminEditor}
            />

            {/* Admin Route Ends */}

            {/* <Route path={`${path}/resources`} component={ResourcesNavigator} /> */}
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </Layout>
  );
};

export default CaringBlocksNavigator;
