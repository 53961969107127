import React from "react";
import { useHistory } from "react-router-dom";

import useTitle from "../../hooks/use-title";

import { FnComponent } from "../../models/component.model";

// import RoleSelection from "../../components/Common/Onboarding/RoleSelection";
// import Verification from "../../components/Common/Onboarding/Verification";
// import Information from "../../components/Common/Onboarding/Information";
import { useOnboardForm } from "../../store/context/onboard-context";
import StepperBar from "../../components/Layout/StepperBar";

const SignUp: FnComponent<{
  // onRouteChange: (image: BGImage) => void;
  title: string;
}> = (props) => {
  // const [showPassword, setShowPassword] = React.useState<boolean>(false);
  // const { onRouteChange } = props;
  const history = useHistory();
  useTitle(props.title);

  const [activeButton, setActiveButton] = React.useState("");

  React.useEffect(() => {
    // console.log(activeButton);
  }, [activeButton]);

  // const handleMouseDownPassword = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   // preventDefault(event);
  // };

  const { activeStep, setActiveStep } = useOnboardForm();

  const steps = [
    "Account type",
    "Email Verification",
    "Your information",
    // "Company info",
  ];

  return (
    <React.Fragment>
      <StepperBar data={steps} />
      Setup
    </React.Fragment>
  );
};

export default SignUp;
