import React, { useState } from "react";
import { FormControl, TextField, Typography, Box, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

export interface Option {
  label: string;
  value: string;
}

interface AutocompleteProps {
  label: string;
  placeholder?: string;
  options: Option[];
  onInputChange: (value: string) => void;
}

const MHAutocomplete: React.FC<AutocompleteProps> = ({
  label,
  placeholder,
  options,
  onInputChange,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>("");

  const handleOptionClick = (optionValue: string) => {
    setSelectedValue(optionValue);
    onInputChange(optionValue);
  };

  return (
    <FormControl fullWidth>
      {label && (
        <label htmlFor="autocomplete-input" style={{ marginBottom: "4px" }}>
          {label}
        </label>
      )}
      <Autocomplete
        id="autocomplete-input"
        options={options}
        getOptionLabel={(option: Option) => option.label || ""}
        renderOption={(props, option: Option) => (
          <li {...props}>
            <Button
              onClick={() => handleOptionClick(option.value)}
              className="removeUnderline no-underline text-start"
              fullWidth
              sx={{
                justifyContent: "start", // Aligns button content to the start
              }}
            >
              <Box
                component="li"
                sx={{
                  display: "flex",
                  justifyContent: "start",
                }}
                padding={{ xs: 1, sm: 1 }}
              >
                <Typography
                  variant="subtitle2"
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  lineHeight={{ xs: "0px", sm: "1px" }}
                  textAlign={{ xs: "start", sm: "start" }}
                  justifyContent="start"
                  color="primary"
                  gutterBottom
                >
                  {option.label}
                </Typography>
              </Box>
            </Button>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: "12px" },
              sx: {
                height: "45px",
                padding: "0 14px",
                border: "none",
                borderRadius: 0,
                textAlign: "start",
              },
            }}
            // inputProps={{
            //   ...params.inputProps,
            //   //   style: { padding: "8.5px 14px" },
            // }}
          />
        )}
      />
    </FormControl>
  );
};

export default MHAutocomplete;
