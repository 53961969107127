import React, { useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import MHDialog from "../../../components/Common/Dialog/MHDialog";
import MHFormControl from "../../../components/Common/Form/MHFormControl";
import StyledActionButton from "../../../components/Common/Button/StyledActionButton";
import useInputArray from "../../../hooks/use-input-array";

import * as validators from "../../../utils/validators";
import MHButton from "../../../components/Common/Button/MHButton";
import useHttp from "../../../hooks/use-http";
import { HttpResponse } from "../../../models/api.interface";
import SnackbarContext from "../../../store/context/snackbar.context";
import AuthContext from "../../../store/context/auth-context";
import useInput from "../../../hooks/use-input";
import { Grid, Typography } from "@mui/material";
import { MHSelect } from "../../../components/Common/Form/MHSelect";
import { resolveErrorMessage } from "../../../utils/utils";
import DashboardContext from "../../../store/context/dashboard.context";
import { useOnboardForm } from "../../../store/context/onboard-context";
import { useHistory } from "react-router-dom";

const ConfirmationDialog = ({
  open,
  onClose,
  content,
  handleApprove,
  handleCancel,
}: {
  open: boolean;
  onClose: () => void;
  content: string;
  handleApprove: () => void;
  handleCancel: () => void;
}) => {
  // const { inputFields, onChange, addField, removeField } = useInputArray([
  //   {
  //     validator: (value: string) => validators.required(value),
  //   },
  // ]);

  // React.useEffect(() => {
  //   addField();
  // }, []);

  const { loading, error, sendHttpRequest: signIn } = useHttp();
  let history = useHistory();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { addToContract } = useOnboardForm();

  const {
    value: enteredTemplate,
    valid: enteredTemplateIsValid,
    error: templateInputHasError,
    onChange: templateInputChangeHandler,
    onBlur: templateInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const formIsValid = enteredTemplateIsValid;

  const handleForm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!formIsValid) {
      // console.log("Complete Form");
      toast({
        message: "Please input template name",
        variant: "warning",
      });
      return;
    }

    // addToContract({
    //   employeeName: enteredEmployeeName,
    // });
    toast({
      message: "Contract Template Saved",
      variant: "success",
    });
    onClose();
    templateInputChangeHandler("");
    history.push("/operations/contract/:id");
  };

  React.useEffect(() => {
    if (error) {
      toast({
        variant: "error",
        message: error.message,
      });
    }
  }, [error]);

  return (
    <MHDialog
      title=" "
      open={open}
      handleClose={onClose}
      maxWidth={"sm"}
      scroll="paper"
      // actions={
      //   <MHButton
      //     type="submit"
      //     form="add-employee-form"
      //     loading={loading}
      //     fullWidth
      //   >
      //     Save Changes
      //   </MHButton>
      // }
      fullWidth
    >
      <Box
        component="form"
        id="add-employee-form"
        // onSubmit={handleForm}
        autoComplete="off"
        noValidate
      >
        <Stack direction="column" alignItems="center" spacing={2}>
          <Typography
            variant="body1"
            align="center"
            color={"primary"}
            fontSize={{ xs: "16px", sm: "19px", md: "19px" }}
            lineHeight={{ xs: "19px", sm: "19px" }}
            letterSpacing={"0.01em"}
            gutterBottom
            className=" mb-6"
          >
            {content}
          </Typography>

          <Grid container spacing={2} className="">
            <Grid item xs={12} className="spaceEvenly">
              <MHButton
                // type="submit"
                form="cancel-form"
                onClick={handleApprove}
                // loading={loading}
                // fullWidth
                sx={{
                  height: 40,
                  width: 100,
                }}
              >
                Yes
              </MHButton>

              <MHButton
                // type="submit"
                form="cancel-form"
                onClick={handleCancel}
                // loading={loading}
                // fullWidth
                sx={{
                  height: 40,
                  width: 100,
                }}
              >
                No
              </MHButton>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </MHDialog>
  );
};

export default ConfirmationDialog;
