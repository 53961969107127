import React from "react";
import { styled } from "@mui/system";

const grey = {
  100: "#E0E3E7",
  300: "#CDD2D7",
  700: "#3E5060",
  900: "#1A2027",
};

const StyledInput = styled("input")<{}>(
  ({ theme }) => `
    font-family: Area-Normal-Semibold;
    font-size: 0.75rem;
    // box-sizing: border-box;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    text-align: left;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    outline: none;
    // border: 1px solid ${grey[100]};
    // padding: 2px;
    width: 100%;
    &:hover {
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[300]};
    }
  `
);

const StyledContainer = styled("div")<{}>(
  ({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    line-height: 1.5;
    margin-bottom: 1.25rem;
    border: 1px solid ${grey[100]};
  `
);

interface MHTimePickerProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  [x: string]: any; // To allow additional props
}

const MHTimePicker: React.FC<MHTimePickerProps> = ({
  value,
  onChange,
  ...props
}) => {
  return (
    <StyledContainer>
      <StyledInput
        aria-label="Time"
        type="time"
        value={value}
        onChange={onChange}
        {...props}
      />
    </StyledContainer>
  );
};

export default MHTimePicker;
