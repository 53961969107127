import React from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";

import MHButton from "../../components/Common/Button/MHButton";
import MHFormControl from "../../components/Common/Form/MHFormControl";
import InputAdornment from "../../components/Common/Form/InputAdornment";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import useTitle from "../../hooks/use-title";

import { ReactComponent as MailIcon } from "../../static/svg/mail.svg";
import { ReactComponent as InfoRoundedIcon } from "../../static/svg/info-rounded.svg";
import { FnComponent } from "../../models/component.model";
// import { BGImage } from "../../models/background-image.model";
import * as validators from "../../utils/validators";
import { HttpResponse } from "../../models/api.interface";
import { EMAIL_FROM } from "../../utils/constants";
import MHPasswordControl from "../../components/Common/Form/MHPasswordControl";
import { EmployeeData } from "../../models/employee.model";

interface RouteParams {
  telephone?: string;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const EmployeeCreatePassword: FnComponent<{
  // onRouteChange: (image: BGImage) => void;
  title: string;
}> = (props) => {
  // const { telephone } = useParams<RouteParams>();

  const query = useQuery();
  const telephone = query.get("telephone");
  // console.log("telephone", telephone);
  // const { onRouteChange } = props;

  const { loading, error, sendHttpRequest: setPasswordLink } = useHttp();
  const { sendHttpRequest: getRefIdAndCustomerId } = useHttp();
  useTitle(props.title);

  const [employees, setEmployees] = React.useState<EmployeeData[]>([]);

  const history = useHistory();

  // const {
  //   value: enteredEmail,
  //   valid: enteredEmailIsValid,
  //   error: emailInputHasError,
  //   onChange: emailInputChangeHandler,
  //   onBlur: emailInputBlurHandler,
  // } = useInput([{ validator: (value: string) => validators.email(value) }]);

  // const emailErrorTip = emailInputHasError ? "Please enter a valid email" : "";

  // React.useEffect(() => {
  //   onRouteChange({
  //     imageSrc:
  //       "https://res.cloudinary.com/mother-honestly/image/upload/v1657836148/lawrence-crayton-KXOaNSU63NE-unsplash_1_c4copk.png",
  //     imageAlt: "Lawrence Crayton",
  //   });
  // }, [onRouteChange]);

  const [password, setPassword] = React.useState("");
  const [employerRefId, setEmployerRefId] = React.useState("");
  const [customerId, setCustomerId] = React.useState("");

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    // console.log("password", password);
  };

  const isValidPassword = validators.validatePassword(
    password,
    8, // minLength
    true, // requiredUppercase
    true, // requiredLowercase
    true, // requiredNumber
    true // requiredSpecialChar
  );
  // console.log(telephone);

  const GetDetails = async () => {
    getRefIdAndCustomerId(
      process.env.REACT_APP_API_BASE_URL +
        `/employee/bytelephone?telephone=${telephone}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      },
      (response: HttpResponse<any>) => {
        // history.push({
        //   pathname: "/auth/sign-in",
        //   state: {
        //     email: enteredEmail,
        //   },
        // });
        if (response.status === 200) {
          // console.log(response.data);
          setCustomerId(response.data.customerId);
          setEmployerRefId(response.data.employerRefId);
        }
      }
    );
  };

  React.useEffect(() => {
    GetDetails();
  }, [telephone]);

  // console.log(employerRefId, customerId);

  const setPasswordLinkHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isValidPassword) {
      return;
    }

    setPasswordLink(
      process.env.REACT_APP_API_BASE_URL + "/employer/password/set",
      {
        method: "POST",
        body: JSON.stringify({
          employerRefId: Number(employerRefId),
          customerId: Number(customerId),
          password: password,
        }),
      },
      (response: HttpResponse<unknown>) => {
        // history.push({
        //   pathname: "/auth/sign-in",
        //   state: {
        //     email: enteredEmail,
        //   },
        // });

        history.push("/operations/auth/sign-in");
      }
    );
  };

  return (
    <React.Fragment>
      <Box className=" place-content-center text-center space-y-6 mb-10">
        <Typography
          variant="h1"
          component="h1"
          fontSize={{ xs: "24px", sm: "30px", md: "30px" }}
          gutterBottom
          mb={1}
        >
          Create Your Password
        </Typography>
      </Box>

      <ListItem
        sx={{
          background: "#FFFFFF",
          border: 0.2,
          borderColor: "#fff",
          color: "#A9A9A9",
          my: 5,
          py: 1,
        }}
      >
        <ListItemAvatar>
          <Avatar
            alt="!"
            sx={{
              bgcolor: "transparent",
            }}
            className=" opacity-60"
          >
            <InfoRoundedIcon width="100%" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText className=" opacity-60">
          Please enter a password to sign the document. This password will also
          serve as your signup password, so choose a combination that you can
          easily remember.
        </ListItemText>
      </ListItem>
      <Paper
        sx={{
          px: 3,
          py: 3,
          width: "100%",
        }}
        className={password ? "h-full" : "h-[400px]"}
      >
        <Box sx={{}}>
          {/* <Typography
            variant="body2"
            color="#6F6F6F"
            component={"div"}
            gutterBottom
            mb={2}
          >
            Let's get you into your account
          </Typography> */}

          {error && (
            <Alert
              severity="error"
              sx={{
                mb: 3,
              }}
            >
              {error.message}
            </Alert>
          )}

          <Box
            component={"form"}
            onSubmit={setPasswordLinkHandler}
            className="py-5 space-y-8"
          >
            <MHPasswordControl
              label="Enter Password"
              value={password}
              onChange={handlePasswordChange}
              minLength={8}
              requiredUppercase={true}
              requiredLowercase={true}
              requiredNumber={true}
              requiredSpecialChar={true}
            />

            {/* {enteredEmail && (
              <ListItem
                sx={{
                  background: "#ECF7FF",
                  border: 0.2,
                  borderColor: "#194049",
                  color: "#6B6B6B",
                  my: 3,
                  py: 3,
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    alt="!"
                    sx={{
                      bgcolor: "transparent",
                    }}
                  >
                    <InfoRoundedIcon width="100%" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  Please enter a password to sign the document. This password
                  will also serve as your signup password, so choose a
                  combination that you can easily remember.
                </ListItemText>
              </ListItem>
            )} */}

            <MHButton
              sx={{ mb: 2 }}
              type="submit"
              loading={loading}
              disabled={!isValidPassword}
              fullWidth
            >
              Sign up
            </MHButton>

            {/* <MuiLink
              component={Link}
              underline="always"
              to="/auth/sign-in"
              display="block"
              align="left"
              className="text-blue-100 removeUnderline"
            >
              Go back to the login page
            </MuiLink> */}
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default EmployeeCreatePassword;
