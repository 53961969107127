/* eslint-disable import/no-cycle */
import React from "react";

import { Box, Backdrop, CircularProgress } from "@mui/material";

const DataLoader = (props: { open: boolean }) => {
  return (
    <Backdrop
      open={props.open}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "rgba(255, 255, 255, 0.8)", // white background with 80% opacity
      }}
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <CircularProgress
          color="secondary"
          size={68}
          sx={{
            color: "#191970",
          }}
        />
      </Box>
    </Backdrop>
  );
};

export default DataLoader;
