import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

export interface SelectOption<T = string> {
  value: T;
  label: React.ReactNode; // This allows the label to be any ReactNode, not just string
}

interface MultipleSelectProps {
  options: SelectOption<string>[]; // Updated type
  selectedValues: string[];
  onChange: (selected: string[]) => void;
  label: string;
}

export const MultipleSelector: React.FC<MultipleSelectProps> = ({
  options,
  selectedValues,
  onChange,
  label,
}) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
    const {
      target: { value },
    } = event;
    onChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={selectedValues}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={{
              fontWeight:
                selectedValues.indexOf(option.value) === -1
                  ? theme.typography.fontWeightRegular
                  : theme.typography.fontWeightMedium,
            }}
          >
            {option.label} {/* ReactNode label */}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
