import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import CreateTraining from "../components/Feature/Training/CreateTraining";
import TrainingTable from "../components/Feature/Training/TrainingTable";

type Props = {};

const Training = (props: Props) => {
  return (
    <React.Fragment>
      <Box>
        <Typography
          variant="subtitle1"
          fontSize={"23px"}
          paddingTop={5}
          paddingBottom={3}
        >
          Training / Certificates
        </Typography>
        <Divider
          light
          variant="middle"
          sx={{
            borderColor: "#F3F4F6",
            my: 2,
          }}
        />
        <CreateTraining />
        <Divider
          light
          variant="middle"
          sx={{
            borderColor: "#F3F4F6",
            my: 3,
          }}
        />
        <TrainingTable />
      </Box>
    </React.Fragment>
  );
};

export default Training;
