import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import MHFormControl from "../../Common/Form/MHFormControl";

import * as validators from "../../../utils/validators";
import useInput from "../../../hooks/use-input";
import MHAutocomplete, { Option } from "../../Common/Form/MHAutocomplete";
import { TrainingLevel, TrainingType } from "../../../utils/constants";
import InputAdornment from "../../Common/Form/InputAdornment";
import MHDatePicker from "../../Common/Form/MHDatePicker";
import dayjs, { Dayjs } from "dayjs";
import MHTimePicker from "../../Common/Form/MHTimePicker";
import { MHSwitch } from "../../Common/Form/MHSwitch";
import MHButton from "../../Common/Button/MHButton";
import useHttp from "../../../hooks/use-http";
import { HttpResponse } from "../../../models/api.interface";
import SnackbarContext from "../../../store/context/snackbar.context";
import { AllHouseholdRoles } from "../../../utils/contracts";
import { MultipleSelector } from "../../Common/Form/MultipleSelector";
import MHTextInput from "../../Common/Form/MHTextInput";

type Props = {};

const CreateTraining = (props: Props) => {
  const {
    value: enteredTitle,
    valid: enteredTitleIsValid,
    onChange: titleInputChangeHandler,
    onBlur: titleInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredCost,
    valid: enteredCostIsValid,
    onChange: costInputChangeHandler,
    onBlur: costInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredOutline,
    valid: enteredOutlineIsValid,
    onChange: outlineInputChangeHandler,
    onBlur: outlineInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredTrainingType,
    valid: enteredTrainingTypeIsValid,
    onChange: trainingTypeInputChangeHandler,
    onBlur: trainingTypeInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredTrainingLevel,
    valid: enteredTrainingLevelIsValid,
    onChange: trainingLevelInputChangeHandler,
    onBlur: trainingLevelInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredCertificate,
    valid: enteredCertificateIsValid,
    onChange: certificateInputChangeHandler,
    onBlur: certificateInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredInstructor,
    valid: enteredInstructorIsValid,
    onChange: instructorInputChangeHandler,
    onBlur: instructorInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredContact,
    valid: enteredContactIsValid,
    onChange: contactInputChangeHandler,
    onBlur: contactInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredAddress,
    valid: enteredAddressIsValid,
    onChange: addressInputChangeHandler,
    onBlur: addressInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const [myCost, setMyCost] = React.useState(0);

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const trainingTypeOptions: Option[] = TrainingType.map((role) => ({
    label: role.label as string,
    value: role.value,
  }));

  const trainingLevelOptions: Option[] = TrainingLevel.map((role) => ({
    label: role.label as string,
    value: role.value,
  }));

  const [startDate, setStartDate] = React.useState<Dayjs | null>(
    dayjs("2024-04-17")
  );

  const [startTime, setStartTime] = React.useState("12:00");
  const [endTime, setEndTime] = React.useState("12:00");

  const handleStartTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartTime(event.target.value);
  };
  const handleEndTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndTime(event.target.value);
  };

  const [costState, setCostState] = React.useState({
    checkedDate: false,
  });

  const [selectedRoles, setSelectedRoles] = React.useState<string[]>([]);
  const selectedRolesString = selectedRoles.join(","); // or any other separator you prefer

  const handleSelectionChange = (selectedOptions: string[]) => {
    // Check if "All" is selected
    const allSelected = selectedOptions.includes(
      "driver/chauffeur,chef/cook,nanny,pet caregiver,domestic couple,laundress,butler,adult caregiver,personal assistant,security guard,home tutor,gardener,housekeeper"
    );

    // If "All" is selected, only keep "All" in the selection
    if (allSelected) {
      setSelectedRoles([
        "driver/chauffeur,chef/cook,nanny,pet caregiver,domestic couple,laundress,butler,adult caregiver,personal assistant,security guard,home tutor,gardener,housekeeper",
      ]);
    } else {
      setSelectedRoles(selectedOptions);
    }
  };

  const handleToggler = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setCheckedDate(event.target.checked);
    setCostState({ ...costState, checkedDate: event.target.checked });
  };

  const { loading, error, sendHttpRequest } = useHttp();

  // Convert Dayjs object to a string (e.g., to ISO format)
  const formattedDate = startDate ? startDate.toISOString() : "";

  //   const price =
  //     costState.checkedDate === false ? costInputChangeHandler("0") : enteredCost;

  //   console.log("price", price);
  // console.log("selectedRolesString", selectedRolesString);

  const myLevel =
    enteredTrainingLevel === "Onboard"
      ? 0
      : enteredTrainingLevel === "Level 1"
      ? 1
      : enteredTrainingLevel === "Level 2"
      ? 2
      : -1;

  // const myFormData = {
  //   title: enteredTitle,
  //   price: myCost,
  //   objectives: enteredOutline,
  //   level: myLevel,
  //   trainingMode: enteredTrainingType,
  //   category: selectedRolesString,
  //   date: formattedDate,
  //   startDate: startTime,
  //   endDate: endTime,
  //   address: enteredAddress,
  //   instructor: enteredInstructor,
  //   enquiryContact: enteredContact,
  //   certName: enteredCertificate,
  //   status: "CREATED",
  //   issuedBy: "Caring Blocks",
  // };
  // console.log("myFormData", myFormData);

  const formIsValid =
    enteredTitleIsValid &&
    // enteredCostIsValid &&
    enteredOutlineIsValid &&
    enteredTrainingLevelIsValid &&
    enteredTrainingTypeIsValid &&
    enteredAddressIsValid &&
    enteredInstructorIsValid &&
    enteredContactIsValid &&
    enteredCertificateIsValid;

  //   console.log("formData", formData);
  //   console.log("enteredTitle", enteredTitle);
  //   console.log("enteredCost", enteredCost);
  //   console.log("enteredOutline", enteredOutline);
  //   console.log("enteredTrainingLevel", enteredTrainingLevel);
  //   console.log("enteredTrainingType", enteredTrainingType);
  //   console.log("startDate", startDate);
  //   console.log("startTime", startTime);
  //   console.log("endTime", endTime);
  //   console.log("enteredAddress", enteredAddress);
  //   console.log("enteredInstructor", enteredInstructor);
  //   console.log("enteredContact", enteredContact);
  //   console.log("enteredCertificate", enteredCertificate);

  const deleteFormData = () => {
    setStartDate(dayjs("2024-04-17"));
    setStartTime("00:00");
    setEndTime("00:00");
    setSelectedRoles([]);
    costInputChangeHandler("");
    titleInputChangeHandler("");
    outlineInputChangeHandler("");
    trainingLevelInputChangeHandler("");
    trainingTypeInputChangeHandler("");
    addressInputChangeHandler("");
    contactInputChangeHandler("");
    instructorInputChangeHandler("");
    certificateInputChangeHandler("");
  };

  const submitCreateTraining = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    // if (!formIsValid) {
    //   return;
    // }

    const formData = new FormData();

    formData.append("title", enteredTitle);
    formData.append("price", `${Number(myCost)}`);
    formData.append("objectives", enteredOutline);
    formData.append("level", `${myLevel}`);
    formData.append("trainingMode", enteredTrainingType);
    formData.append("category", selectedRolesString);
    formData.append("date", formattedDate);
    formData.append("startDate", startTime);
    formData.append("endDate", endTime);
    formData.append("address", enteredAddress);
    formData.append("instructor", enteredInstructor);
    formData.append("enquiryContact", enteredContact);
    formData.append("certName", enteredCertificate);
    formData.append("status", "CREATED");
    formData.append("issuedBy", "Caring Blocks");

    // Function to check if any FormData field is empty
    const isFormDataValid = () => {
      let isValid = true;

      formData.forEach((value, key) => {
        if (value === "") {
          isValid = false;
        }
      });

      return isValid; // Returns true if all fields are non-empty, otherwise false
    };

    if (!isFormDataValid()) {
      // Handle the case where one or more fields are empty
      // console.log("Please fill out all required fields.");
      toast({
        variant: "error",
        message: "Please fill out all required fields.",
      });
    } else {
      // Proceed with form submission

      if (myLevel === -1) {
        // console.log("Kindly fill out all required fields.");
        return toast({
          variant: "error",
          message: "Kindly fill out all required fields.",
        });
      }

      // console.log("enteredTrainingLevel", enteredTrainingLevel);
      // console.log("myLevel", myLevel);
      // console.log("enteredTrainingType", enteredTrainingType);
      // console.log("Form data is valid. Proceeding with submission...");

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/training/store`,
          {
            method: "POST",
            body: formData,
            // Note: You generally don't need to set 'Content-Type' for FormData.
            // The browser will automatically set it including the boundary.
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          console.error("Error:", errorData);
          toast({
            variant: "error",
            message: "Failed to create training: " + errorData.message,
          });
          return;
        }

        const data = await response.json();
        toast({
          variant: "success",
          message: "Training created successfully",
        });

        // Optional: Clear the form data
        deleteFormData();
      } catch (error) {
        console.error("Request failed:", error);
        toast({
          variant: "error",
          message: "An error occurred while creating the training.",
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Box component="form" onSubmit={submitCreateTraining}>
        <Grid container spacing={1} className="lg:px-0 place-content-center">
          <Grid item xs={12} sm={6} md={4} lg={4} className="lg:px-4">
            <MHFormControl
              id="title"
              type="text"
              label="Training Title"
              placeholder="Title"
              value={enteredTitle}
              onChange={titleInputChangeHandler}
              onBlur={titleInputBlurHandler}
              required
              // autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} className="lg:px-4">
            <MHAutocomplete
              label="Training Type"
              placeholder="Select type"
              options={trainingTypeOptions}
              onInputChange={(val) =>
                trainingTypeInputChangeHandler(val as string)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} className="lg:px-4">
            {costState.checkedDate === true ? (
              // <MHFormControl
              //   id="cost"
              //   type="number"
              //   label="Training Cost"
              //   placeholder="Cost"
              //   value={enteredCost}
              //   onChange={costInputChangeHandler}
              //   onBlur={costInputBlurHandler}
              //   startAdornment={
              //     <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
              //       &#x20A6;
              //     </InputAdornment>
              //   }
              //   required
              //   // autoFocus
              // />

              <MHTextInput
                id="cost"
                type="text"
                placeholder="Cost"
                value={myCost}
                onChange={(e) => setMyCost(Number(e.target.value))}
                // onBlur={costInputBlurHandler}
                startAdornment={
                  <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                    &#x20A6;
                  </InputAdornment>
                }
              />
            ) : (
              <label className=" font-areaSemi text-xs text-[#194049]">
                Training Cost
              </label>
            )}

            <Box display="flex" alignItems="center">
              <MHSwitch
                value={costState.checkedDate}
                onChange={handleToggler}
              />

              {costState.checkedDate === false ? (
                <Typography
                  variant="body2"
                  fontSize="0.9rem"
                  sx={{
                    opacity: 0.9,
                  }}
                >
                  Free
                </Typography>
              ) : null}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={1} className="lg:px-0 place-content-center">
          <Grid item xs={12} sm={4} md={4} lg={4} className="lg:px-4">
            <MHFormControl
              id="outline"
              type="text"
              label="Training Outline"
              placeholder="List the objectives of this training"
              value={enteredOutline}
              onChange={outlineInputChangeHandler}
              onBlur={outlineInputBlurHandler}
              multiline
              rows={6}
              required
              // autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} className="lg:px-4">
            <label className=" font-areaSemi text-xs text-[#6B6B6B]">
              Training Date
            </label>

            <MHDatePicker
              value={startDate}
              onChange={(newvalue) => setStartDate(newvalue)}
            />
            <MHAutocomplete
              label="Training Level"
              placeholder="Select level"
              options={trainingLevelOptions}
              onInputChange={(val) =>
                trainingLevelInputChangeHandler(val as string)
              }
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} className="lg:px-4">
            <label className=" font-areaSemi text-xs text-[#6B6B6B]">
              Start Time
            </label>

            {/* <MHDatePicker
              value={startDate}
              onChange={(newvalue) => setStartDate(newvalue)}
            /> */}

            <MHTimePicker value={startTime} onChange={handleStartTime} />

            <label className=" font-areaSemi text-xs text-[#6B6B6B]">
              End Time
            </label>

            <MHTimePicker value={endTime} onChange={handleEndTime} />
          </Grid>
        </Grid>

        <Grid container spacing={1} className="lg:px-0 place-content-center">
          <Grid item xs={12} sm={6} md={4} lg={4} className="lg:px-4">
            <MHFormControl
              id="certificate"
              type="text"
              label="Certificate"
              placeholder="Certificate"
              value={enteredCertificate}
              onChange={certificateInputChangeHandler}
              onBlur={certificateInputBlurHandler}
              required
              // autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} className="lg:px-4">
            <MHFormControl
              id="instructor"
              type="text"
              label="Instructor"
              placeholder="Instructor"
              value={enteredInstructor}
              onChange={instructorInputChangeHandler}
              onBlur={instructorInputBlurHandler}
              required
              // autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} className="lg:px-4">
            <MHFormControl
              id="contact"
              type="text"
              label="Enquiry Contact"
              placeholder="Contact"
              value={enteredContact}
              onChange={contactInputChangeHandler}
              onBlur={contactInputBlurHandler}
              required
              // autoFocus
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} className="lg:px-0 place-content-start">
          <Grid item xs={12} sm={6} md={8} lg={8} className="lg:px-4">
            <MHFormControl
              id="address"
              type="text"
              label="Address"
              placeholder="Address"
              value={enteredAddress}
              onChange={addressInputChangeHandler}
              onBlur={addressInputBlurHandler}
              required
              // autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} className="lg:px-4">
            <MultipleSelector
              options={AllHouseholdRoles}
              selectedValues={selectedRoles}
              onChange={handleSelectionChange}
              label="Select Category"
            />
          </Grid>
        </Grid>

        <Box
          display={{ xs: "flex", sm: "flex" }}
          justifyContent="end"
          width={{ xs: "full", sm: "full" }}
          className="lg:px-4 mt-4"
        >
          <MHButton
            sx={{ height: "44px", width: { xs: "full", sm: "40%" } }}
            // onClick={handleSavePassword}
            loading={loading}
            type="submit"
            fullWidth
            className="rounded-sm"
          >
            Create Training
          </MHButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default CreateTraining;
