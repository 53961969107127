import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import DataLoader from "../components/Common/Loading/DataLoader";
import { BackgroundProps, HealthProps } from "../models/assesment.model";
import useHttp from "../hooks/use-http";
import { convertUTCToDate, getURLWithQueryParams } from "../utils/utils";
import { HttpResponse } from "../models/api.interface";
import MHDataTable, {
  GridColDef,
} from "../components/Common/DataTable/MHDataTable";
import { ReactComponent as CheckIcon } from "../static/svg/check-mark-rounded-lg.svg";
import { ReactComponent as CancelIcon } from "../static/svg/cancel-mark-rounded-lg.svg";
import MHButton from "../components/Common/Button/MHButton";

type Props = {};

const Screening = (props: Props) => {
  const [bgChecks, setBgChecks] = React.useState<BackgroundProps[]>([]);

  const { sendHttpRequest } = useHttp();

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/background-check/getby",
        {
          newRequest: String("true"),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<any[]>) => {
        setBgChecks(response.data);
      }
    );
  }, []);

  const [healthChecks, setHealthChecks] = React.useState<HealthProps[]>([]);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/background-check/health/getby",
        {
          newRequest: String(""),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<any[]>) => {
        setHealthChecks(response.data);
      }
    );
  }, []);

  // console.log("bgChecks", bgChecks);
  // console.log("healthChecks", healthChecks);
  // console.log("completedBgChecks", completedBgChecks);
  // console.log("pendingBgChecks", pendingBgChecks);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // React.useEffect(() => {
  //   if (value === 0) {
  //     setSelectedContract(completedBgChecks);
  //   } else {
  //     setSelectedContract(pendingBgChecks);
  //   }
  // }, [value, completedBgChecks, pendingBgChecks]);

  const healthColumns: GridColDef<HealthProps>[] = [
    {
      headerName: "Date",
      type: "text",
      field: "createdDate",
      width: 100,
      cellRenderer: (row: HealthProps) => (
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
          lineHeight={{ xs: "100%", sm: "100%" }}
          letterSpacing={"0.01em"}
          alignSelf={"center"}
          className=" opacity-40 capitalize"
        >
          {convertUTCToDate(row?.createdDate)}
        </Typography>
      ),
    },

    {
      headerName: "Employer Ref ID",
      type: "text",
      field: "employerRefId",
      width: 200,
      valueGetter: (row: HealthProps) => {
        return row?.employerRefId;
      },
    },

    {
      headerName: "Employee ID",
      type: "text",
      field: "customerId",
      width: 200,
      valueGetter: (row: HealthProps) => {
        return row?.customerId;
      },
    },

    {
      headerName: "Description",
      type: "text",
      field: "description",
      width: 400,
      // valueGetter: (row: HealthProps) => {
      //   return row?.description;
      //   // Hi, {authCtx.user?.firstName}
      // },
      cellRenderer: (row: HealthProps) => (
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
          lineHeight={{ xs: "120%", sm: "120%" }}
          width={400}
          height="auto"
          letterSpacing={"0.01em"}
          alignSelf={"center"}
          noWrap
          className=" line-clamp-1 "
        >
          {row?.description}
        </Typography>
      ),
    },
    {
      headerName: "Medical Cost",
      type: "text",
      field: "medHistoryCheckCost",
      width: 200,
      valueGetter: (row: HealthProps) => {
        return "₦" + row?.medHistoryCheckCost;
      },
    },

    {
      headerName: "Status",
      type: "text",
      field: "medicalStatus",
      width: 100,
      valueGetter: (row: HealthProps) => {
        return row?.medicalStatus;
      },
    },
    {
      headerName: "Action",
      type: "text",
      field: "medicalStatus",
      width: 100,
      valueGetter: (row: HealthProps) => {
        return row?.medicalStatus !== "COMPLETED" ? (
          <MHButton
            form="cancel-form"
            // onClick={handleApprove}
            sx={{ height: "50%" }}
          >
            Upload
          </MHButton>
        ) : (
          <MHButton
            form="cancel-form"
            // onClick={handleApprove}
            sx={{ height: "50%" }}
          >
            Preview
          </MHButton>
        );
      },
    },
  ];

  const bgColumns: GridColDef<BackgroundProps>[] = [
    {
      headerName: "Date",
      type: "text",
      field: "createdDate",
      width: 100,
      cellRenderer: (row: BackgroundProps) => (
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
          lineHeight={{ xs: "100%", sm: "100%" }}
          letterSpacing={"0.01em"}
          alignSelf={"center"}
          className=" opacity-40 capitalize"
        >
          {convertUTCToDate(row?.createdDate)}
        </Typography>
      ),
    },

    {
      headerName: "Employer Ref ID",
      type: "text",
      field: "employerRefId",
      width: 200,
      valueGetter: (row: BackgroundProps) => {
        return row?.employerRefId;
      },
    },

    {
      headerName: "Employee ID",
      type: "text",
      field: "customerId",
      width: 200,
      valueGetter: (row: BackgroundProps) => {
        return row?.customerId;
      },
    },

    // {
    //   headerName: "Id Check",
    //   type: "text",
    //   field: "idCheck",
    //   width: 400,
    //   cellRenderer: (row: BackgroundProps) => (
    //     <Typography
    //       variant="body1"
    //       color="primary"
    //       fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
    //       lineHeight={{ xs: "120%", sm: "120%" }}
    //       width={400}
    //       height="auto"
    //       letterSpacing={"0.01em"}
    //       alignSelf={"center"}
    //       noWrap
    //       className=" line-clamp-1 "
    //     >
    //       {row?.idCheck === false ? "NO" : "YES"}
    //     </Typography>
    //   ),
    // },
    {
      headerName: "Identity",
      type: "text",
      field: "idVerified",
      width: 200,
      valueGetter: (row: BackgroundProps) => {
        return row?.idVerified === false ? (
          <CancelIcon className="w-5 h-5" />
        ) : (
          <CheckIcon className="w-5 h-5" />
        );
      },
    },
    {
      headerName: "Address",
      type: "text",
      field: "addressVerified",
      width: 200,
      valueGetter: (row: BackgroundProps) => {
        return row?.addressVerified === false ? (
          <CancelIcon className="w-5 h-5" />
        ) : (
          <CheckIcon className="w-5 h-5" />
        );
      },
    },
    {
      headerName: "Education",
      type: "text",
      field: "educationVerfied",
      width: 200,
      valueGetter: (row: BackgroundProps) => {
        return row?.educationVerfied === false ? (
          <CancelIcon className="w-5 h-5" />
        ) : (
          <CheckIcon className="w-5 h-5" />
        );
      },
    },
    {
      headerName: "Employment",
      type: "text",
      field: "employmentVerified",
      width: 200,
      valueGetter: (row: BackgroundProps) => {
        return row?.employmentVerified === false ? (
          <CancelIcon className="w-5 h-5" />
        ) : (
          <CheckIcon className="w-5 h-5" />
        );
      },
    },
    {
      headerName: "Guarantor",
      type: "text",
      field: "guarantorVerified",
      width: 200,
      valueGetter: (row: BackgroundProps) => {
        return row?.guarantorVerified === false ? (
          <CancelIcon className="w-5 h-5" />
        ) : (
          <CheckIcon className="w-5 h-5" />
        );
      },
    },
  ];

  if (healthChecks.length === 0 && bgChecks.length === 0) {
    return <DataLoader open />;
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          //   color: "#194049",
          paddingX: "20px",
        }}
      >
        <Typography
          variant="subtitle1"
          fontSize={"19px"}
          paddingTop={5}
          paddingBottom={3}
        >
          Screenings / Assessments
        </Typography>

        <Box sx={{ width: "100%" }}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{ textTransform: "capitalize", fontSize: "12px" }}
                label="Health Assessment"
                {...a11yProps(0)}
              />
              <Tab
                sx={{ textTransform: "capitalize", fontSize: "12px" }}
                label="Background Check"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
        </Box>

        {/* Body */}
        {value === 0 ? (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} className=" order-2 md:order-1">
              <MHDataTable
                title=""
                rows={healthChecks}
                columns={healthColumns}
                frontEndPagination
                // hidePagination
              />
            </Grid>
          </Grid>
        ) : value === 1 ? (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} className=" order-2 md:order-1">
              <MHDataTable
                title=""
                rows={bgChecks}
                columns={bgColumns}
                frontEndPagination
                // hidePagination
              />
            </Grid>
          </Grid>
        ) : null}
      </Box>
    </React.Fragment>
  );
};

export default Screening;
