import { Box, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as TranxDocIcon } from "../../../static/svg/tranxdoc.svg";
import { ReactComponent as CheckIcon } from "../../../static/svg/check-unfilled.svg";
import { ReactComponent as ClockIcon } from "../../../static/svg/clock.svg";
// import { formatAmount } from "../../../utils/utils";
import { formatTimeAgo } from "../../../utils/getdate";
import MHButton from "../../Common/Button/MHButton";
import SnackbarContext from "../../../store/context/snackbar.context";
import useHttp from "../../../hooks/use-http";

type Props = {
  id?: string | number;
  reference?: string | number;
  name?: string | number;
  // price?: number;
  time?: string | any;
  // status?: string | any;
  status?: string | any;
  handleRefetch?: (e: React.FormEvent) => void;
};

const AttendeeCard = (props: Props) => {
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { loading, error, sendHttpRequest } = useHttp();

  const myFormData = {
    reference: props.reference,
    customerId: props.id,
    attendanceStatus: "ATTENDED",
  };

  const updateStatus = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // if (!formIsValid) {
    //   return;
    // }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/training/employee`,
        {
          method: "PUT",
          // body: formData,
          headers: {
            "Content-Type": "application/json", // Ensure headers are set to handle JSON
          },
          body: JSON.stringify(myFormData),
          // Note: You generally don't need to set 'Content-Type' for FormData.
          // The browser will automatically set it including the boundary.
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error:", errorData);
        toast({
          variant: "error",
          message: "Failed to update training: " + errorData.message,
        });
        return;
      }

      const data = await response.json();
      toast({
        variant: "success",
        message: "Training attended successfully",
      });

      if (props.handleRefetch) {
        props.handleRefetch(event); // Correctly pass the event to handleRefetch
      }
    } catch (error) {
      console.error("Request failed:", error);
      toast({
        variant: "error",
        message: "An error occurred while attending the training.",
      });
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-evenly", my: 2 }}>
      <Box sx={{ display: "flex", gap: 3, justifyContent: "center" }}>
        <Box
          sx={{
            width: "29px",
            height: "29px",
            borderRadius: "50%",
            backgroundColor:
              props.status === "ATTENDED"
                ? "#8EBC76"
                : props.status === "ASSIGNED"
                ? "#9400D3"
                : "#FA635A",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography
            variant="subtitle2"
            color={"#fff"}
            fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
            lineHeight={{ xs: "19px", sm: "19px" }}
            letterSpacing={"0.01em"}
            className="pt-1"
          >
            {props.status && props.status[0]}
          </Typography>
        </Box>

        <Box sx={{ position: "relative", width: "200px" }}>
          <Typography
            variant="subtitle2"
            color={"#0060F0"}
            fontSize={{ xs: "9px", sm: "9px", md: "9px" }}
            lineHeight={{ xs: "19px", sm: "19px" }}
            letterSpacing={"0.01em"}
            className=" line-clamp-1"
          >
            Customer ID: {props.id}
          </Typography>
          <Typography
            variant="subtitle1"
            color={"#194049"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            lineHeight={{ xs: "19px", sm: "19px" }}
            letterSpacing={"0.01em"}
            className=" line-clamp-2"
          >
            {props.name}
          </Typography>

          {props.status && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 1,
                width: "fit-content",
                height: "12px",
                borderRadius: "2px",
                backgroundColor:
                  props.status === "ATTENDED"
                    ? "#D2E0CB"
                    : props.status === "ASSIGNED"
                    ? "#E6E6FA"
                    : "#F2CECE",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Typography
                variant="body1"
                color={
                  props.status === "ATTENDED"
                    ? "#49812D"
                    : props.status === "ASSIGNED"
                    ? "#9400D3"
                    : "#FF0F00"
                }
                fontSize={{ xs: "8px", sm: "8px", md: "8px" }}
                lineHeight={{ xs: "19px", sm: "19px" }}
                letterSpacing={"0.01em"}
                className="px-1"
              >
                {props.status}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <TranxDocIcon />
        <Box>
          <Typography
            variant="subtitle2"
            color={"#3E3D44"}
            fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
            lineHeight={{ xs: "19px", sm: "19px" }}
            letterSpacing={"0.01em"}
            className=""
          >
            Training Ref
          </Typography>
          <Typography
            variant="subtitle1"
            color={"#194049"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            lineHeight={{ xs: "19px", sm: "19px" }}
            letterSpacing={"0.01em"}
            className=""
          >
            {props.reference}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <ClockIcon />
        <Box>
          <Typography
            variant="body1"
            color={"#9D9D9D"}
            fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
            lineHeight={{ xs: "19px", sm: "19px" }}
            letterSpacing={"0.01em"}
            className=""
          >
            {formatTimeAgo(props.time)}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", gap: 1, alignItems: "center" }}
        component="form"
        onSubmit={updateStatus}
      >
        <MHButton
          sx={{ height: "24px", width: { xs: "full", sm: "full" } }}
          // onClick={updateStatus}
          loading={loading}
          startIcon={<CheckIcon className=" text-white" />}
          type="submit"
          fullWidth
          className="rounded-sm"
          disabled={props.status === "ATTENDED" ? true : false}
        >
          Attend
        </MHButton>
      </Box>
    </Box>
  );
};

export default AttendeeCard;
