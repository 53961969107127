import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import { styled } from "@mui/material/styles";

import AuthNavigator from "./AuthNavigator";
// import DashboardNavigator from "./DashboardNavigator";
// import NotFound from "../pages/Error/404";
// import ErrorPage from "../pages/Error/404NotFound";

import AuthContext from "../store/context/auth-context";
import { FnComponent } from "../models/component.model";
import CaringBlocksNavigator from "./CaringBlocksNavigator";
import Index from "../pages/Index";
// import SignIn from "../pages/Auth/SignIn"; // Import your sign-in component here
import Layout from "../components/Layout/Layout";
import NotFoundPage from "../pages/Error/404NotFound";
import SignIn from "../pages/Auth/SignIn";
import { Box, Grid } from "@mui/material";
// import { AuthNavBar, HomeNavBar } from "../components/Layout/AppBar";

// const Wrapper = styled("div")(
//   ({ theme }) => `
//     padding: ${theme.spacing(3)};
//   `
// );

const AppNavigator: FnComponent<{}> = (props) => {
  const authCtx = React.useContext(AuthContext);

  return (
    <React.Fragment>
      <Switch>
        <Route path="/" exact>
          <Layout>
            <React.Fragment>
              <Box sx={{ flexGrow: 1 }} bgcolor="#FAFAFA">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={0}
                  sx={{ minHeight: "90vh" }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    md={5}
                    sx={{
                      // backgroundColor: backgroundColor.current,
                      position: "relative",
                      px: 2,
                    }}
                  >
                    <SignIn title="Sign in " />
                  </Grid>
                </Grid>
              </Box>
            </React.Fragment>
          </Layout>
        </Route>

        <Route path="/auth">
          <AuthNavigator />
        </Route>

        <Route path="/operations">
          {authCtx.isAuthenticated === true ? (
            <React.Fragment>
              {/* <Wrapper> */}
              <CaringBlocksNavigator />
              {/* </Wrapper> */}
            </React.Fragment>
          ) : (
            <Redirect to="/auth/sign-in" />
          )}
        </Route>

        <Route path="/404" component={NotFoundPage} />
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default AppNavigator;
