import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import TranxCard from "./TranxCard";
import { TransactionProps } from "../../../models/transaction.model";
import useHttp from "../../../hooks/use-http";
// import dayjs from "dayjs";
import { getURLWithQueryParams } from "../../../utils/utils";
import DashboardContext from "../../../store/context/dashboard.context";
// import { HttpResponse } from "../../../models/api.interface";
import { MHPagination } from "../../Common/UI/MHPagination";

type Props = {};

const Transactions = (props: Props) => {
  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;
  const { loading, error, sendHttpRequest } = useHttp();

  const [transactions, setTransactions] = React.useState<TransactionProps[]>(
    []
  );
  const [tranxType, setTranxType] = React.useState<string>("");
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const postsPerPage = 6;

  // Fetch transactions
  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/tranx/history",
        { type: String("") } // Update based on filtering or dynamic values
      ),
      { method: "GET" },
      (response) => {
        setTransactions(response.data);
      }
    );
  }, [organization, sendHttpRequest]);

  // Filter transactions based on selected type
  const filteredTransactions = React.useMemo(() => {
    return transactions.filter((tranx) => {
      return tranxType === "" || tranx.transType === tranxType;
    });
  }, [transactions, tranxType]);

  // Reset current page when filtering transactions
  React.useEffect(() => {
    setCurrentPage(1); // Reset page when the transaction type changes
  }, [tranxType]);

  // Pagination logic
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredTransactions.slice(
    firstPostIndex,
    lastPostIndex
  );
  const noOfPage = Math.ceil(filteredTransactions.length / postsPerPage);

  // Handle page change
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Box
        sx={{
          width: { xs: "825px" },
          height: { xs: "512px" },
          border: "1px solid #E8E8E8",
          borderRadius: "8px",
          mt: 3,
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between" }}
          pt={3}
          px={4}
        >
          <Box>
            <Typography
              variant="subtitle1"
              color={"primary"}
              fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "19px", sm: "19px" }}
              letterSpacing={"0.01em"}
              className=" text-start"
            >
              Latest Transactions
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
            <Button className=" no-underline" onClick={() => setTranxType("")}>
              <Typography
                variant="subtitle2"
                color={tranxType === "" ? "primary" : "#BBC5C9"}
                fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                lineHeight={{ xs: "19px", sm: "19px" }}
                letterSpacing={"0.01em"}
                className=""
              >
                All
              </Typography>
            </Button>
            <Button
              className=" no-underline"
              onClick={() => setTranxType("Employer")}
            >
              <Typography
                variant="subtitle2"
                color={tranxType === "Employer" ? "primary" : "#BBC5C9"}
                fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                lineHeight={{ xs: "19px", sm: "19px" }}
                letterSpacing={"0.01em"}
                className=""
              >
                Employers
              </Typography>
            </Button>
            <Button
              className=" no-underline"
              onClick={() => setTranxType("Employee")}
            >
              <Typography
                variant="subtitle2"
                color={tranxType === "Employee" ? "primary" : "#BBC5C9"}
                fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                lineHeight={{ xs: "19px", sm: "19px" }}
                letterSpacing={"0.01em"}
                className=""
              >
                Employees
              </Typography>
            </Button>
          </Box>
        </Box>
        {/* <Divider sx={{ mt: 2 }} /> */}

        <Box
          sx={{
            overflow: "hidden",
            width: { xs: "auto" },
            height: { xs: "440px" },
            mt: 3,
          }}
        >
          {currentPosts.length > 0 ? (
            currentPosts.map((transaction, index) => (
              <Box key={`${transaction.reference}-${index}`}>
                <Divider sx={{ mt: 2 }} />
                <TranxCard
                  id={transaction.reference}
                  price={transaction.amount}
                  customerId={transaction.customerId}
                  name={`${transaction.firstName} ${transaction.lastName}`}
                  type={transaction.type}
                  transType={transaction.transType}
                  time={transaction.CreatedDate}
                />
              </Box>
            ))
          ) : (
            <Typography variant="subtitle1" textAlign="center" my={3}>
              No transactions available.
            </Typography>
          )}
        </Box>

        <Box display={"flex"} justifyContent={"center"} my={2}>
          <MHPagination
            count={noOfPage}
            variant="outlined"
            shape="rounded"
            page={currentPage}
            onChange={handlePageChange}
            color={"primary"}
          />
        </Box>
      </Box>
    </>
  );
};

export default Transactions;
