import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import TrainCard from "./TrainCard";
import {
  // TrainingArrayProps,
  TrainingProps,
} from "../../../models/training.model";
import useHttp from "../../../hooks/use-http";
import { getURLWithQueryParams } from "../../../utils/utils";
import { HttpResponse } from "../../../models/api.interface";
import { formatDateWithoutDay } from "../../../utils/getdate";
import dayjs from "dayjs";
import { MHPagination } from "../../Common/UI/MHPagination";

type Props = {};

const TrainingTable = (props: Props) => {
  const { sendHttpRequest } = useHttp();

  const [trainings, setTrainings] = React.useState<TrainingProps[]>([]);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/training/all",
        {
          employerRefId: String("ABC"),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<any>) => {
        setTrainings(response.data);
        // console.log("response 1", response.data.data);
        // console.log("response 2", response.data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("trainings", trainings);

  const filteredTrainings = trainings.filter(
    (training) => training.status !== "INACTIVE"
  );

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(5);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredTrainings.slice(firstPostIndex, lastPostIndex);

  let itemsPerPage = 5;
  let noOfPage = Math.ceil((filteredTrainings?.length || 0) / itemsPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const headers = [
    { label: "Title", width: "18%" },
    { label: "Type", width: "10%" },
    { label: "Outline", width: "22%" },
    { label: "Date", width: "10%" },
    { label: "Time", width: "10%" },
    { label: "Cost", width: "10%" },
    { label: "Attendees", width: "10%" },
    { label: "Action", width: "10%" },
  ];

  function formatTimeWithDate(
    date: Date | string | undefined,
    time: string | undefined
  ): string {
    if (!date || !time) return "Invalid time";

    // Combine date and time
    const combinedDateTime = `${dayjs(date).format("YYYY-MM-DD")}T${time}`;

    // Parse the combined datetime and format it
    return dayjs(combinedDateTime).isValid()
      ? dayjs(combinedDateTime).format("hh:mm A")
      : "Invalid Date";
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "4px",
          boxShadow: "0px 0px 5px 2px #E8E8E8",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-evenly",
            py: "20px",
            px: "20px",
          }}
        >
          {headers.map((item) => (
            <Box sx={{ flexBasis: item.width }} key={item.label}>
              <Typography variant="subtitle1" fontSize={"12px"}>
                {item.label}
              </Typography>
            </Box>
          ))}
        </Box>

        {currentPosts &&
          currentPosts.map((item) => (
            <Box key={item.id}>
              <Divider sx={{ mt: 0 }} />

              <TrainCard
                id={item.id}
                reference={item.reference}
                title={item.title}
                mode={item.trainingMode}
                objectives={item.objectives}
                date={dayjs(item.date).format("DD/MM/YYYY")}
                // time={item.startDate}
                time={formatTimeWithDate(item.date, item.startDate)}
                attendees={3000}
                price={item.price}
                training={item}
              />
              {/* <Divider sx={{ mt: -1 }} /> */}
            </Box>
          ))}
        <Divider sx={{ mt: 0 }} />

        <Box display={"flex"} justifyContent={"center"} my={2} pb={3}>
          <MHPagination
            count={noOfPage}
            variant="outlined"
            shape="rounded"
            page={currentPage}
            onChange={handlePageChange}
            color={"primary"}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default TrainingTable;
