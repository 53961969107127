import React from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import MHFormControl from "../../components/Common/Form/MHFormControl";
import MHButton from "../../components/Common/Button/MHButton";
import InputAdornment from "../../components/Common/Form/InputAdornment";
import IconButton from "../../components/Common/Button/IconButtonStyled";
import useHttp from "../../hooks/use-http";
import useTitle from "../../hooks/use-title";

// import { ReactComponent as GoogleIcon } from "../../static/svg/google-icon.svg";
import { ReactComponent as MailIcon } from "../../static/svg/mail.svg";
import { ReactComponent as LockIcon } from "../../static/svg/lock.svg";
import { ReactComponent as VisibilityIcon } from "../../static/svg/visibility.svg";
import { ReactComponent as VisibilityOffIcon } from "../../static/svg/visibility-off.svg";
// import { BGImage } from "../../models/background-image.model";
import { FnComponent } from "../../models/component.model";
import { theme } from "../../theme/mui/dashboard.theme";
import { HttpResponse } from "../../models/api.interface";
import * as formReducer from "../../store/reducers/form";
import * as validators from "../../utils/validators";
import AuthContext from "../../store/context/auth-context";
// import NotificationContext from "../../store/context/notifications.context";
import DashboardContext from "../../store/context/dashboard.context";
import { Divider } from "@mui/material";
import MHRadioGroup from "../../components/Common/Form/MHRadioGroup";
import useInput from "../../hooks/use-input";
import SnackbarContext from "../../store/context/snackbar.context";
import MHPasswordControl from "../../components/Common/Form/MHPasswordControl";

const SignIn: FnComponent<{
  // onRouteChange: (image: BGImage) => void;
  title: string;
}> = (props) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  // const { onRouteChange } = props;
  const history = useHistory();
  useTitle(props.title);

  // const notificationCtx = React.useContext(NotificationContext);
  // const { pushNotification, popNotification } = notificationCtx;

  const dashboardCtx = React.useContext(DashboardContext);

  const { organization, fetchOrganizationData } = dashboardCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // React.useEffect(() => {
  //   const notificationId =
  //     queryParams.get("verify") &&
  //     pushNotification({
  //       message:
  //         "You're all set up! Please login with your work email and password to continue",
  //       type: "success",
  //       duration: 10000,
  //     });

  //   return () => popNotification(notificationId || 0);
  // }, []);

  const authCtx = React.useContext(AuthContext);
  const { loading, error, sendHttpRequest: signIn } = useHttp();

  const [formState, dispatch] = React.useReducer(formReducer.formReducer, {
    email: {
      value: "",
      valid: false,
      required: true,
      dirty: false,
      validating: false,
      validators: [
        {
          validator: (value: string) => validators.email(value),
        },
      ],
    },
    password: {
      value: "",
      valid: false,
      required: true,
      dirty: false,
      validating: false,
      validators: [
        {
          validator: (value: string) => validators.minLength(8)(value),
        },
      ],
    },

    formIsValid: false,
  });

  const [enteredRole, setEnteredRole] = React.useState("Employer");

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredRole((event.target as HTMLInputElement).value);
  };

  // React.useEffect(() => {
  //   onRouteChange({
  //     imageSrc:
  //       "https://res.cloudinary.com/mother-honestly/image/upload/v1657835660/juliane-liebermann-O-RKu3Aqnsw-unsplash_1_zv7sov.png",
  //     imageAlt: "Juliane Liebermann",
  //     background: theme.palette.common.white,
  //   });
  // }, [onRouteChange]);

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const signinHandler = async (event: React.ChangeEvent<HTMLFormElement>) => {
    // emailInputRef.current!.focus();
    preventDefault(event);

    if (!formState.email.value.includes("mhworklife")) {
      toast({
        message: "Authorized admin",
        variant: "error",
      });
      return;
    }

    if (!formState.formIsValid && enteredRole === "") {
      toast({
        message: "Please input all fields and select a role.",
        variant: "error",
      });
      return;
    }

    // console.log("login body", formState.email.value, formState.password.value,formState.role.value);
    // if (enteredRole === "Employer") {
    signIn(
      process.env.REACT_APP_API_BASE_URL + "/employer/login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: formState.email.value,
          password: formState.password.value,
          role: enteredRole,
        }),
      },
      (response: HttpResponse<any>) => {
        authCtx.login(
          response.data.token,
          response.data.employerRefId,
          response.data.uuid,
          response.data.role
        );

        // console.log(organization?.subscriptionType);
        if (authCtx?.role === "Employer") {
          history.push("/operations/dashboard");
        } else if (authCtx?.role === "Employee") {
          history.push("/operations/employee/dashboard");
        }
        // if (organization?.subscriptionType === "") {
        //   history.push("/operations/subscription");
        // } else {
        //   history.push("/operations/dashboard");
        // }
      }
    );
    // } else if (enteredRole === "Employee") {
    //   // console.log("Selected Employee");
    //   toast({
    //     message: "Successfully selected employee",
    //     variant: "success",
    //   });
    // }
  };

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: formReducer.SET_FORM_DATA,
      value: event.target.value,
      id: event.target.id,
    });
  };

  const handleClickShowPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    preventDefault(event);
    setShowPassword((prevState) => !prevState);
  };

  // const handleMouseDownPassword = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   // preventDefault(event);
  // };

  // Redirect if user is signed in already
  if (authCtx.isAuthenticated === true && authCtx.role === "Employer") {
    return <Redirect to="/operations/dashboard" />; //should redirect to household if role is employee
  }

  return (
    <React.Fragment>
      <Paper
        sx={{
          px: 3,
          py: 3,
          boxShadow: "0px 10px 16px rgba(154, 154, 154, 0.13)",
        }}
        className="h-[100%] max-w-md mx-auto"
      >
        <Box className="text-center">
          <Typography
            variant="h1"
            fontSize={{ xs: "24px", sm: "28px", md: "28px" }}
            component="div"
            gutterBottom
            mb={1.5}
          >
            Admin Sign in
          </Typography>
        </Box>
        <Box
          sx={{
            mb: 4,
          }}
        ></Box>

        {error && (
          <Alert
            severity="error"
            sx={{
              mb: 3,
            }}
          >
            {error.message}
          </Alert>
        )}

        {/* autoComplete="off"
            noValidate */}
        <Box component={"form"} onSubmit={signinHandler}>
          <MHFormControl
            id="email"
            type="email"
            label="Email address"
            placeholder="Enter your email"
            onChange={inputChangeHandler}
            startAdornment={
              <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                <MailIcon width="1rem" />
              </InputAdornment>
            }
            required
            // autoFocus
          />

          <MHFormControl
            id="password"
            type={showPassword ? "text" : "password"}
            label="Password"
            placeholder="Enter your password"
            onChange={inputChangeHandler}
            startAdornment={
              <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                <LockIcon width="1.2rem" />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment>
                <IconButton onClick={handleClickShowPassword}>
                  {showPassword ? (
                    <VisibilityOffIcon width="1rem" height="1rem" />
                  ) : (
                    <VisibilityIcon width="1rem" height="1rem" />
                  )}
                </IconButton>
              </InputAdornment>
            }
            required
          />

          <MHButton sx={{}} type="submit" loading={loading} fullWidth>
            Sign in
          </MHButton>

          {/* <Box
            sx={{
              typography: "body1",
              textAlign: "left",
            }}
            mt={1}
            mb={1}
            onClick={preventDefault}
          >
            <MuiLink
              className="text-blue-100 removeUnderline"
              component={Link}
              href=""
              to="/auth/sing-up"
            >
              Onboard now
            </MuiLink>
          </Box> */}
          <Box
            sx={{
              // typography: "body1",
              textAlign: "right",
              // display: "flex",
              // justifyContent: "space-end",
            }}
            mt={1}
            mb={1}
            onClick={preventDefault}
          >
            {/* <MuiLink
              className="text-blue-100 removeUnderline "
              component={Link}
              href=""
              to="/auth/onboard"
            >
              Onboard now
            </MuiLink> */}

            <MuiLink
              className="text-blue-100 removeUnderline"
              component={Link}
              href=""
              to="/auth/forgot-password"
            >
              Forgot password?
            </MuiLink>
          </Box>

          {/* <Box className="w-full flex justify-center items-center">
            <Divider className="w-[45%]" />
            <Box className="w-[10%] text-center">
              <Typography color="primary" className="opacity-60">
                Or
              </Typography>
            </Box>

            <Divider className="w-[45%]" />
          </Box> */}

          {/* <MHButton
            sx={{}}
            // type="submit"
            // loading={loading}
            // onClick={handleGoogleSignIn}
            fullWidth
            className="h-12 mt-4 text-opacity-50 bg-white text-navy-900 ring-1 ring-navy-900 ring-opacity-30"
            startIcon={<GoogleIcon />}
          >
            Sign in with Google
          </MHButton> */}
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default SignIn;
