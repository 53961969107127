import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import TextCard from "../components/Feature/General/TextCard";
import Transactions from "../components/Feature/Dashboard/Transactions";
import { ReactComponent as WalletIcon } from "../static/svg/wallet.svg";
import { ReactComponent as WalletPlainIcon } from "../static/svg/wallet-plain.svg";
import { ReactComponent as VisibleIcon } from "../static/svg/visibility.svg";
import { ReactComponent as VisibleOffIcon } from "../static/svg/visibility-off.svg";
import RoundedLogoIcon from "../theme/icons/RoundedLogo";
import { formatAmount, getURLWithQueryParams } from "../utils/utils";
import useHttp from "../hooks/use-http";
import { DashboardProps } from "../models/admin.model";
import { HttpResponse } from "../models/api.interface";
import MHButton from "../components/Common/Button/MHButton";
import useDialog from "../hooks/use-dialog";
import PaymeDialog from "../components/Feature/Dashboard/PaymeDialog";

type Props = {};

const Dashboard = (props: Props) => {
  const [view, setView] = React.useState(false);

  const { sendHttpRequest } = useHttp();

  const [dashData, setDashData] = React.useState<DashboardProps>();

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/admin/business_info",
        {
          employerRefId: String("ABC"),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<any>) => {
        setDashData(response);
        // console.log("response 1", response.data.data);
        // console.log("response 2", response.data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const myData = dashData?.data;

  // console.log("dashData", dashData);

  return (
    <React.Fragment>
      <Box
        sx={{
          //   color: "#194049",
          paddingX: "20px",
        }}
      >
        {/* <Typography
          variant="subtitle1"
          fontSize={"23px"}
          paddingTop={5}
          paddingBottom={3}
        >
          Dashboard
        </Typography> */}

        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography
            variant="subtitle1"
            fontSize={"23px"}
            paddingTop={5}
            paddingBottom={3}
          >
            Dashboard
          </Typography>
          {/* <Box>
            <MHButton
              variant="outlined"
              color="primary"
              startIcon={<WalletIcon />}
              sx={{
                borderRadius: "4px",
              }}
              onClick={handleOpenDialog}
            >
              Bill Employer
            </MHButton>
          </Box> */}
        </Stack>

        <Box className="grid lg:flex lg:flex-row lg:gap-6">
          <Box
            // sx={{ display: { sm: "flex" }, gap: { xs: 3, md: 8 } }}
            className="  lg:basis-[70%] "
          >
            <Box
              sx={{ display: { sm: "flex" }, gap: { xs: 3, md: 1 } }}
              className="  lg:basis-[70%] grid grid-cols-2 lg:grid-cols-3"
            >
              <TextCard
                title="No of Employers"
                count={myData?.employers?.count}
                color="#F6FFF2"
                // state={true}
              />
              <TextCard
                title="Employers With Funds"
                count={myData?.employers?.employersThatFundedAccount}
                color="#F2F4FF"
                // state={true}
              />
              <TextCard
                title="No of Households"
                count={myData?.employees?.count}
                color="#FFF7F2"
                // state={true}
                autoHeight={true}
              />
              {/* <TextCard title="No of Care Centers" count={300} color="#F2F4FF"state={true} /> */}
            </Box>
            <Box className="  lg:basis-[10%]">
              <Transactions />
            </Box>
          </Box>

          <Box className="  lg:basis-[20%]">
            <Box
              sx={{
                width: "295px",
                height: "auto",
                border: "1px solid #e8e8e8",
                boxShadow: "0px 0px 5px 2px #E8E8E8",
                zIndex: 10,
                borderRadius: "8px",
                justifyContent: "center",
                // marginX: "auto",
              }}
              //   className=" !shadow-2xl !drop-shadow-2xl"
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 3,
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 2,
                }}
              >
                <RoundedLogoIcon
                  sx={{
                    p: 0.6,
                    mb: 2,
                    backgroundColor: "#C7D8E4",
                  }}
                >
                  <WalletIcon className="w-6 h-6 " />
                </RoundedLogoIcon>
                <Typography variant="body1" fontSize={"16px"}>
                  Balance Details
                </Typography>

                <RoundedLogoIcon
                  sx={{
                    // p: 0.6,
                    // mb: 2,
                    backgroundColor: "inherit",
                  }}
                >
                  {view === false ? (
                    <VisibleIcon
                      className="w-8 h-8 -mt-2 cursor-pointer"
                      onClick={() => setView(true)}
                    />
                  ) : (
                    <VisibleOffIcon
                      className="w-8 h-8 -mt-2 cursor-pointer "
                      onClick={() => setView(false)}
                    />
                  )}
                </RoundedLogoIcon>
              </Box>
              <Box
                sx={{
                  display: "grid",

                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextCard
                  title="Total Balance"
                  count={formatAmount(myData?.totalBalance || 0)}
                  color="#F6FFF2"
                  autoHeight={false}
                  hideVisibility={view}
                />
                <TextCard
                  title="Caring Blocks Wallet"
                  count={formatAmount(myData?.caringBlocksWallet || 0)}
                  color="#F2F4FF"
                  autoHeight={false}
                  hideVisibility={view}
                />
                <TextCard
                  title="Health Assessment Wallet"
                  count={formatAmount(myData?.healthAssessmentWallet || 0)}
                  color="#fff7f2"
                  autoHeight={false}
                  hideVisibility={view}
                />
                <TextCard
                  title="Background Check Wallet"
                  count={formatAmount(myData?.BackGroundCheckWallet || 0)}
                  color="#EEEEEE"
                  autoHeight={false}
                  hideVisibility={view}
                />
                <TextCard
                  title="Training Wallet"
                  count={formatAmount(myData?.trainingWallet || 0)}
                  color="#EAEEE8"
                  autoHeight={false}
                  hideVisibility={view}
                />
                <TextCard
                  title="HMO Wallet"
                  count={formatAmount(myData?.hmo || 0)}
                  color="#F9F9F7"
                  autoHeight={false}
                  hideVisibility={view}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Dashboard;
