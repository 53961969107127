import { Box, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as TranxDocIcon } from "../../../static/svg/tranxdoc.svg";
import { ReactComponent as ClockIcon } from "../../../static/svg/clock.svg";
import { formatAmount } from "../../../utils/utils";
import { formatTimeAgo } from "../../../utils/getdate";

type Props = {
  id?: string | number;
  name?: string | number;
  customerId?: string | number;
  price?: number;
  time?: string | any;
  transType?: string | any;
  type?: string | any;
};

const TranxCard = (props: Props) => {
  // console.log(props);
  return (
    <Box sx={{ display: "flex", justifyContent: "space-evenly", my: 2 }}>
      <Box sx={{ display: "flex", gap: 3, justifyContent: "center" }}>
        <Box
          sx={{
            width: "29px",
            height: "29px",
            borderRadius: "50%",
            backgroundColor: props.type === "CREDIT" ? "#8EBC76" : "#FA635A",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography
            variant="subtitle2"
            color={"#fff"}
            fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
            lineHeight={{ xs: "19px", sm: "19px" }}
            letterSpacing={"0.01em"}
            className="pt-1"
          >
            {props.type && props.type[0]}
          </Typography>
        </Box>

        <Box sx={{ position: "relative", width: "200px" }}>
          <Typography
            variant="subtitle2"
            color={"#0060F0"}
            fontSize={{ xs: "9px", sm: "9px", md: "9px" }}
            lineHeight={{ xs: "19px", sm: "19px" }}
            letterSpacing={"0.01em"}
            className=" line-clamp-1"
          >
            ID: {props.id}
          </Typography>
          <Typography
            variant="subtitle1"
            color={"#194049"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            lineHeight={{ xs: "19px", sm: "19px" }}
            letterSpacing={"0.01em"}
            className=" line-clamp-2"
          >
            {props.name === " " ? props.customerId : props.name}
          </Typography>

          {props.transType && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 1,
                width: "fit-content",
                height: "12px",
                borderRadius: "2px",
                backgroundColor:
                  props.type === "CREDIT" ? "#D2E0CB" : "#F2CECE",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Typography
                variant="body1"
                color={props.type === "CREDIT" ? "#49812D" : "#FF0F00"}
                fontSize={{ xs: "8px", sm: "8px", md: "8px" }}
                lineHeight={{ xs: "19px", sm: "19px" }}
                letterSpacing={"0.01em"}
                className="px-1"
              >
                {props.transType}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <TranxDocIcon />
        <Box>
          <Typography
            variant="subtitle2"
            color={"#3E3D44"}
            fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
            lineHeight={{ xs: "19px", sm: "19px" }}
            letterSpacing={"0.01em"}
            className=""
          >
            Amount
          </Typography>
          <Typography
            variant="subtitle1"
            color={"#194049"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            lineHeight={{ xs: "19px", sm: "19px" }}
            letterSpacing={"0.01em"}
            className=""
          >
            {formatAmount(props.price || 0)}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <ClockIcon />
        <Box>
          <Typography
            variant="body1"
            color={"#9D9D9D"}
            fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
            lineHeight={{ xs: "19px", sm: "19px" }}
            letterSpacing={"0.01em"}
            className=""
          >
            {formatTimeAgo(props.time)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TranxCard;
