import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { detailsItems, menuItems, userItems } from "../../utils/data";

let drawerWidth = 240;

type SideProp = {
  children: React.ReactNode;
};

export const SideTab = (props: SideProp) => {
  let history = useHistory();
  let location = useLocation();

  return (
    <Box component="div" sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#f9f9f7",
            marginX: 0,
            marginY: 10,
          },
        }}
      >
        <Box paddingTop={5} />

        <List>
          {menuItems.map((item: any) => (
            <ListItem
              key={item.text}
              button
              onClick={() => history.push(item.path)}
              sx={
                location.pathname === item.path
                  ? { background: "#D2E0CB" }
                  : null
              }
            >
              <ListItemIcon
                sx={
                  location.pathname === item.path ? { color: "#56873D" } : null
                }
              >
                {React.cloneElement(item.icon, {
                  style: {
                    fill:
                      location.pathname === item.path ? "#56873D" : "#BBC5C9",
                  },
                })}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={
                  location.pathname === item.path ? { color: "#56873D" } : null
                }
              />
            </ListItem>
          ))}
        </List>

        <Box paddingTop={3} />

        <List>
          <div>
            <Typography
              variant="body1"
              fontSize={"12px"}
              sx={{
                textAlign: "start",
                marginLeft: 2,
                marginBottom: 2,
                color: "#C5C5C5",
              }}
            >
              Users
            </Typography>
          </div>
          {userItems.map((item: any) => (
            <ListItem
              key={item.text}
              button
              onClick={() => history.push(item.path)}
              sx={
                location.pathname === item.path
                  ? {
                      background: "#D2E0CB",
                      color: "#56873D",
                    }
                  : null
              }
            >
              <ListItemIcon
                sx={
                  location.pathname === item.path ? { color: "#56873D" } : null
                }
              >
                {React.cloneElement(item.icon, {
                  style: {
                    fill:
                      location.pathname === item.path ? "#56873D" : "#BBC5C9",
                  },
                })}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={
                  location.pathname === item.path
                    ? { color: "#56873D", fontSize: "12px" }
                    : { color: "inherit", fontSize: "12px" }
                }
              />
            </ListItem>
          ))}
        </List>

        <Divider sx={{ width: "85%", marginX: "auto", marginTop: 2 }} />

        <Box paddingTop={3} />

        <List>
          {detailsItems.map((item: any) => (
            <ListItem
              key={item.text}
              button
              onClick={() => history.push(item.path)}
              sx={
                location.pathname === item.path
                  ? {
                      background: "#D2E0CB",
                      color: "#56873D",
                    }
                  : null
              }
            >
              <ListItemIcon
                sx={
                  location.pathname === item.path ? { color: "#56873D" } : null
                }
              >
                {React.cloneElement(item.icon, {
                  style: {
                    fill:
                      location.pathname === item.path ? "#56873D" : "#BBC5C9",
                  },
                })}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={
                  location.pathname === item.path
                    ? { color: "#56873D", fontSize: "12px" }
                    : { color: "inherit", fontSize: "12px" }
                }
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        component="div"
        sx={{
          backgroundColor: "#fff",
          width: "100%",
          //   padding: "20px",
          //   paddingTop: "80px",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
